import KanbanColumn from '../Components/KanbanColumn'
import CreateColumn from '../Elements/CreateColumn'
import { useParams } from 'react-router-dom'
import { requests } from '../../requests'
import { useEffect } from 'react'

import { DragDropContext } from 'react-beautiful-dnd'
import { dragEnd } from '../CustomHooks/DragAndDrop'

import { setBoardData } from '../../pipeline/BasicPipelines'
import { useDispatch } from 'react-redux'
import { setTasks } from '../../store/TaskProperties'
import { useSelector } from 'react-redux'
import { updateCard } from '../../pipeline/BasicPipelines'

function KanbanBoar() {
    const { projectId } = useParams()
    const dispatch = useDispatch()

    const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

    const tasks = useSelector(state => state.tasks.tasks)
    const tags = useSelector(state => state.tasks.tags)
    const project = useSelector(state => state.teams.projects.find(item => item.id === Number(projectId)))

    const statuses = project?.statusesResponse?.statuses.toSorted((a, b) => a.positionInOrder - b.positionInOrder) || []

    const createNewTask = (column, title) => {
        requests.createTask(projectId, title, column, data => {
            const newTasks = [data, ...tasks]
            setTasksBoard(newTasks) // rework with positionInOrder
        })
    }

    function onDragEnd(result) {
        const { destination, draggableId, source } = result

        if (!destination) {
            return
        }

        const dInd = Number(destination.droppableId)
        const index = Number(destination.index)
        const droppableId = Number(source.droppableId)
        const oldIndex = Number(source.index)

        requests.moveToStatus(draggableId, dInd, index)

        const newState = dragEnd({ dInd, index, draggableId, droppableId, oldIndex, arrTasks: tasks })

        setTasksBoard(newState)
    }

    const update = (taskData, settings) => updateCard({ projectId, taskData }, settings)

    useEffect(() => {
        setBoardData(dispatch, projectId)

        return () => {}
    }, [projectId, dispatch])

    return (
        <div className="app-tabs__content js--tabs-content position-relative active pt-6 w-100">
            <div className="d-flex w-100">
                <div className="app-boards d-flex js--boards-search">
                    <DragDropContext onDragEnd={onDragEnd}>
                        {statuses &&
                            statuses.map(status => (
                                <KanbanColumn
                                    status={status}
                                    key={status.statusId}
                                    createNewTask={createNewTask}
                                    tasks={tasks
                                        .filter(task => task.statusId === status.statusId)
                                        .sort((a, b) => a.positionInOrder - b.positionInOrder)}
                                    tags={tags}
                                    updateCard={update}
                                    projectUsers={project.userInfos}
                                />
                            ))}
                    </DragDropContext>
                </div>
                <CreateColumn />
            </div>
        </div>
    )
}

export default KanbanBoar
