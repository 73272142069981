//import { ReactComponent as DataIcon } from '../../assets/kanban/data.svg'
import { ReactComponent as Data2Icon } from '../../assets/data2.svg'

import { useRef, useState, useEffect } from 'react'
import { useStateVisibleComponent } from '../CustomHooks/ClickOutside'
import { createPortal } from 'react-dom'
import { daysUntilDate, calculateCoordinateHTMLElement, dayTitle } from '../../pipeline/helpers'

function DeadlineCard({ deadline, update, typeView = 'data' }) {
    const [data, setData] = useState(deadline || '')

    useEffect(() => {
        setData(deadline)
    }, [ deadline ])

    const ref = useRef(null)
    const refOverlay = useRef(null)
    const [isOpenOverlayData, setIsOpenOverlayData] = useStateVisibleComponent(refOverlay, () =>
        setData(deadline || '')
    )

    const { x, y } = calculateCoordinateHTMLElement(ref)

    const dateInDates = daysUntilDate(deadline)

    return (
        <div className="app-dropdown cursor-pointer position-relative svg-block js--dropdown" ref={ref}>
            {typeView === 'data' && (
                <div
                    className="app-dropdown__open js--dropdown-open"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()

                        setIsOpenOverlayData(!isOpenOverlayData)
                    }}
                >
                    <p className="d-flex align-items-center">
                        {dateInDates > 0 && dateInDates < 4 && <span className="me-2">🔥</span>}
                        {dateInDates > 0 && dateInDates} { dateInDates > 0 && dayTitle(dateInDates)}
                        {dateInDates < 1 && <span className="me-2">🔥🔥🔥</span>}
                    </p>
                </div>
            )}

            {typeView === 'icon' && (
                <div
                    className="app-tooltip app-tooltip__data position-relative d-flex flex-column align-items-center"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()

                        setIsOpenOverlayData(!isOpenOverlayData)
                    }}
                >
                    <div className="app-tooltip__btn flex-center cursor-pointer svg-block w-100 h-100">
                        <Data2Icon />
                    </div>
                    <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                        <p>Сроки</p>
                    </div>
                </div>
            )}
            {createPortal(
                <>
                    {isOpenOverlayData && (
                        <div
                            ref={refOverlay}
                            className="app-dropdown__overlay js--dropdown-content open"
                            onClick={e => e.stopPropagation()}
                            style={{
                                top: y + 10 + 'px',
                                left: x + 'px',
                                zIndex: 5000,
                            }}
                        >
                            <div className="pt-4 pb-4 ps-9 pe-9">
                                <p className="p-0">Select date</p>
                                {/* <div className="mt-9 d-flex align-items-center justify-content-between svg-block">
                                    <p>Enter dates</p>
                                    <DataIcon />
                                </div> */}
                            </div>
                            <div className="app-border3 w-100"></div>
                            <div className="d-flex justify-content-between align-items-center p-4 w-100">
                                <div className="me-4">
                                    <div className="input-grey">
                                        <input
                                            className="cursor-pointer w-100"
                                            type="date"
                                            placeholder="mm/dd/yyyy"
                                            value={data}
                                            onChange={e => {
                                                e.preventDefault()
                                                e.stopPropagation()

                                                setData(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end mb-4">
                                <button
                                    className="btn pt-10 pb-10 ps-6 pe-6 text-little js--dropdown-content-close"
                                    onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()

                                        setIsOpenOverlayData(false)
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn pt-10 pb-10 ps-6 pe-6 text-little js--dropdown-content-close"
                                    onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()

                                        update({ deadline: data })
                                        setIsOpenOverlayData(false)
                                        setData(deadline || '')
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    )}
                </>,
                document.body
            )}
        </div>
    )
}

export default DeadlineCard
