export function commentsRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }) {
    return {
        getTaskComments: (taskId, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + '/' + taskId + '/comments'

            const params = {
                ...PARAMS,
                method: 'GET',
            }

            sendRequest(path, params, on, off)
        },
        addComment: (taskId, text, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + '/' + taskId + '/comment'

            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify({
                    text,
                }),
            }

            sendRequest(path, params, on, off)
        },
    }
}
