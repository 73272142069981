import TeamSettingMenu from "../Components/TeamSettingMenu"
import { useNavigate } from "react-router-dom"

function TeamSetting({ children }) {
    const navigate = useNavigate()
    return (
        <main className="page-cabinet">
            <div className="page-cabinet-bg"></div>
            <div style={{height: "60px"}}></div>
            <div className="page-cabinet__header d-flex align-items-center justify-content-between">
                <p className="d-flex align-items-center font-medium cursor-pointer" onClick={() => navigate('..')}>
                    {/* <span className="svg-block svg-w--24 p-2">{{> _images/arrow4}}</span> */}
                    Назад
                </p>
                <div className="app-content__avatar flex-center cursor-pointer" 
                
                ></div>
            </div>
            <div className="container mt-4 d-flex flex-row justify-content-end align-items-start js--cabinet">
                <TeamSettingMenu />
                <div className="page-cabinet__contents ms-9 mb-7">
                    {children}
                </div>
            </div>
        </main>
    )
}

export default TeamSetting