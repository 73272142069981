import { useNavigate } from "react-router-dom"


function TarifTeamInfo({ billingPlan, team, projects }) {
    const { name, maxProjects, maxUsers } = billingPlan
    const navigate = useNavigate()

    return (
        <div className="app-tariff__sidebar p-4 pt-6 pb-6">
            <div className="app-tariff__sidebar-row d-flex justify-content-between align-items-center w-100 pb-5">
                <p>Тарифный план</p>
                <p className="color-blue font-bold">{ name }</p>
            </div>
            <div className="app-tariff__sidebar-row d-flex justify-content-between align-items-center w-100 pt-6 pb-5">
                <p>Количество проектов</p>
                <p className="font-bold color-primary">{projects?.length}/{maxProjects}</p>
            </div>
            <div className="app-tariff__sidebar-row d-flex justify-content-between align-items-center w-100 pt-6 pb-5">
                <p>Количество пользователей</p>
                <p className="font-bold color-primary">{team?.membersInfo.length}/{ maxUsers }</p>
            </div>
            <p className="btn btn-primary w-100 mt-5 flex-center color-white" onClick={() => navigate('profile')}>
                Настроить
            </p>
        </div>
    )
}

export default TarifTeamInfo
