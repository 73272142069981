import { useState } from 'react'
//import { ReactComponent as IconLogo } from '../../assets/logo.svg'

function LogIn({ switcToRegistrate, on, onReset }) {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const [typeView, setTypeView] = useState('login')

    return (
        <>
            <header>
                <div className="login-container mt-8">
                    <header>
                        <div className="login-container">
                            <div className="header__wrapper">
                                <div className="header__item">
                                    {/* <div className="logo mt-4">
                                        <IconLogo />
                                    </div> */}
                                </div>
                                <div className="header__item registration">
                                    <button className="btn-submit cursor-pointer" onClick={() => switcToRegistrate()}>
                                        Регистрация
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </header>
            <section className="form">
                <form action="#">
                    {typeView === 'login' && <h1>Войти</h1>}
                    {typeView === 'resetPass' && <h1>Восстановить пароль</h1>}
                    <div className="input">
                        <input
                            type="email"
                            placeholder="Email"
                            value={login}
                            onChange={e => setLogin(e.target.value)}
                        />
                    </div>
                    {typeView === 'login' && (
                        <div className="input">
                            <input
                                type="password"
                                placeholder="Пароль"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    )}
                    <div className="submit">
                        <button
                            className="btn-submit cursor-pointer"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()

                                typeView === 'login' && on(login, password)
                                typeView === 'resetPass' && onReset(login)
                            }}
                        >
                            {typeView === 'login' && 'Войти'}
                            {typeView === 'resetPass' && 'Восстановить'}
                        </button>
                    </div>

                    <p
                        className="text-medium mt-1 color-grey-text2 d-flex justify-content-center cursor-pointer"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()

                            typeView === 'login' && setTypeView('resetPass')
                            typeView === 'resetPass' && setTypeView('login')
                        }}
                    >
                        {typeView === 'login' && 'Забыли пароль?'}
                        {typeView === 'resetPass' && 'Войти'}
                    </p>
                </form>
            </section>
        </>
    )
}

export default LogIn
