import { useSelector } from "react-redux"

function Notification() {
    const { isView, type, text } = useSelector(state => state.notification)

    const addClass = (isView)? " do-show" : ""
    return (
        <>
            <div className={"bottom-right notify" + addClass} data-notification-status={type}>
                { text }
            </div>
        </>
    )
}

export default Notification