export function hashCode(str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
}

export function intToRGB(i) {
    let c = (i & 0x00ffffff).toString(16).toUpperCase()

    return '#' + '00000'.substring(0, 6 - c.length) + c
}

export function deleteDuplicateObject(arr, prop = 'id') {
    const res = []

    for (let index = 0; index < arr.length; index++) {
        let isCopy = false
        for (let i = 0; i < res.length; i++) {
            if (res[i][prop] === arr[index][prop]) {
                isCopy = true
                break
            }
        }

        if (!isCopy) res.push(arr[index])
    }

    return res
}

export function daysUntilDate(targetDateStr) {
    const targetDate = new Date(targetDateStr)
    const currentDate = new Date()

    // Рассчитываем разницу в миллисекундах между целевой и текущей датами
    const timeDifference = targetDate - currentDate
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

    return daysDifference + 1
}

export function dayTitle(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    let last_num = number%10;
    if (last_num === 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}

export function calculateCoordinateHTMLElement(ref) {
    const top =
        (ref &&
            ref.current &&
            ref.current.getBoundingClientRect().y + ref.current.getBoundingClientRect().height) ||
        0
    const left = (ref && ref.current && ref.current.getBoundingClientRect().x) || 0

    return {
        x: left,
        y: top
    }
}

export function imagetoblob(ImgId) {
	// Split the base64 string in data and contentType
    console.log(ImgId,'ImgId');
	let block = ImgId.split(';')
	// Get the content type of the image
	let contentType = block[0].split(':')[1] // In this case "image/gif"
	// get the real base64 content of the file
	let realData = block[1].split(',')[1] // In this case "R0lGODlhPQBEAPeoAJosM...."

	// Convert it to a blob to upload
	return b64toBlob(realData, contentType)
}

function b64toBlob(b64Data, contentType, sliceSize) {
	contentType = contentType || ''
	sliceSize = sliceSize || 512

	let byteCharacters = atob(b64Data)
	let byteArrays = []

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		let slice = byteCharacters.slice(offset, offset + sliceSize)

		let byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}

		let byteArray = new Uint8Array(byteNumbers)

		byteArrays.push(byteArray)
	}

	let blob = new Blob(byteArrays, { type: contentType })
	return blob
}

export function readUploadFile(id, files, on) {
    for (let index = 0; index < files.length; index++) {
        const element = files[index]
        let reader = new FileReader()

        reader.onload = e => {
            let file = {
                data: e.target.result,
                name: element.name,
                type: element.type,
                id: id,
            }

            on(id, file)
        }
        reader.readAsDataURL(element)
    }
}

export function fetchWithAuthentication(url, authToken) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${authToken}`)
    return fetch(url, { headers })
}

export function _arrayBufferToBase64(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export function searchInStrings(searchTerm, arr, nameProp) {
    if (searchTerm === '') return arr

    const searchString = searchTerm.trim().toLowerCase()

    return arr.filter(item => item[nameProp].trim().toLowerCase().includes(searchString))
}

export function getParticipantsLabelRussian(count) {
    if (!count) return ''

    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;
  
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return 'участников';
    } else if (lastDigit === 1) {
      return 'участник';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return 'участника';
    } else {
      return 'участников';
    }
}