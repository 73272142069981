import BoardSpace from './view/Containers/BoardSpace'
import TaskProperties from './view/Containers/TaskProperties'
import ModalCreateColumn from './view/Elements/ModalCreateColumn'
//import ProfileSetting from './view/Components/ProfileSettings'
import LeftMenu from './view/Components/LeftMenu'
import ProjectHeader from './view/Containers/ProjectHeader'

import { Routes, Route } from 'react-router-dom'

import './slyle/main.scss'

export function WorkSpace() {

    return (
        <main className="d-flex h-100 hv-100">
            {/* <MainMenu /> */}
            <LeftMenu type={'projects'}/>
            <div className="app-content pt-6 ps-6 pe-7 me-6 w-100 scroll-x">
                {/* <Header /> */}
                <ProjectHeader />

                <div className="mt-9 w-100">
                    <BoardSpace />
                </div>
            </div>
            <Routes>
                <Route
                    path="/:taskId"
                    element={<TaskProperties />}
                />
            </Routes>
            
            <ModalCreateColumn />
            {/* <ProfileSetting /> */}
        </main>
    )
}
