import { useSelector } from 'react-redux'
import { useState } from 'react'
import IconAvatar from '../Elements/IconAvatar'

function TeamUserRow({ userId, accessRights = '', onDeleteUser = false }) {
    const [isViewDelete, setViewDelete] = useState(false)
    const user = useSelector(state => state.users.users.find(us => us.userId === userId))

    return (
        <div className="app-table__cabinet-row d-flex align-items-center justify-content-between pt-9 pb-9">
            <div className="app-table__cabinet-col d-flex align-items-center">
                {/* <div className="app-content__avatar app-content__avatar-small flex-center cursor-pointer me-4">
                    <img src='{{table_image}}' alt="" className="image-contain"/>
                </div> */}
                <IconAvatar
                    userId={userId}
                    addClass="app-content__avatar app-content__avatar-small flex-center cursor-pointer me-4"
                    onClick={() => {}}
                />
                <div>
                    <p className="font-medium">{user.name}</p>
                    <p className="text-underline-none">{user.email}</p>
                </div>
            </div>
            <div className="app-table__cabinet-col d-flex flex-shrink">{/* <p>{user.lastActivityTime}</p> */}</div>
            <div className="app-table__cabinet-col d-flex flex-shrink">
                <p>{accessRights}</p>
            </div>
            <div className="app-table__cabinet-col d-flex flex-column flex-shrink">
                <p>{user.lastActivityTime}</p>
                {/* {{#if table_year}}
                    <p className="color-grey text-little">{{table_year}}</p>
                {{/if}} */}
            </div>
            {onDeleteUser && (
                <div className="app-table__cabinet-col d-flex flex-shrink">
                    {/* {{> app-dropdown/dropdown-delete2 }} */}
                    <div className="app-dropdown app-dropdown__delete cursor-pointer position-relative svg-block js--dropdown">
                        <div className="cursor-pointer js--dropdown-open" onClick={() => setViewDelete(!isViewDelete)}>
                            <SvgInfo />
                        </div>
                        {isViewDelete && (
                            <div className="app-dropdown__overlay d-flex align-items-center pt-5 pb-5 ps-4 pe-4 js--dropdown-content js--dropdown-content-close open">
                                <p className="text-little color-cabinet" onClick={() => onDeleteUser && onDeleteUser()}>
                                    Удалить участника
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default TeamUserRow

function SvgInfo() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99992 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.53978 10.4602 9.16669 9.99992 9.16669C9.53968 9.16669 9.16658 9.53978 9.16658 10C9.16658 10.4603 9.53968 10.8334 9.99992 10.8334Z"
                fill="black"
            />
            <path
                d="M15.8333 10.8334C16.2935 10.8334 16.6666 10.4603 16.6666 10C16.6666 9.53978 16.2935 9.16669 15.8333 9.16669C15.373 9.16669 14.9999 9.53978 14.9999 10C14.9999 10.4603 15.373 10.8334 15.8333 10.8334Z"
                fill="black"
            />
            <path
                d="M4.16659 10.8334C4.62682 10.8334 4.99992 10.4603 4.99992 10C4.99992 9.53978 4.62682 9.16669 4.16659 9.16669C3.70635 9.16669 3.33325 9.53978 3.33325 10C3.33325 10.4603 3.70635 10.8334 4.16659 10.8334Z"
                fill="black"
            />
            <path
                d="M9.99992 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.53978 10.4602 9.16669 9.99992 9.16669C9.53968 9.16669 9.16658 9.53978 9.16658 10C9.16658 10.4603 9.53968 10.8334 9.99992 10.8334Z"
                stroke="#121212"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.8333 10.8334C16.2935 10.8334 16.6666 10.4603 16.6666 10C16.6666 9.53978 16.2935 9.16669 15.8333 9.16669C15.373 9.16669 14.9999 9.53978 14.9999 10C14.9999 10.4603 15.373 10.8334 15.8333 10.8334Z"
                stroke="#121212"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.16659 10.8334C4.62682 10.8334 4.99992 10.4603 4.99992 10C4.99992 9.53978 4.62682 9.16669 4.16659 9.16669C3.70635 9.16669 3.33325 9.53978 3.33325 10C3.33325 10.4603 3.70635 10.8334 4.16659 10.8334Z"
                stroke="#121212"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
