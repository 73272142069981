import IconAvatar from "./IconAvatar"

function CommentTask({ content, creationTime, user, isUtility }) {
    console.log();
    return (
        <div className="mt-6">
            <div className="d-flex align-items-center">
                {/* <div className="circle-24 bg-violet color-white text flex-center me-2">S</div> */}
                <IconAvatar userId={user.userId} addClass="circle-24 bg-violet color-white text flex-center me-2" />
                <p className="text-little">{ user.name }</p>
            </div>
            <div className="mt-2 ms-6 ps-2 d-flex justify-content-between">
                <div>
                    {isUtility && <p className="text-small mt-2">Внес изменение в задаче</p>}
                    <p className="text-little mt-10 app-line app-line-success">
                        {/* Изменил статус задачи №1 на “<span className="color-success">На проверке</span>” */}
                        { content }
                    </p>
                </div>
                <div className="text-small mt-10 color-hoar">
                    { creationTime }
                </div>
                
            </div>
        </div>
    )
}

export default CommentTask
