import ChoiceTariffPopup from '../Components/ChoiceTariffPopup'

import { useState } from 'react'
import { conversionCurrency } from '../../pipeline/PaymentPipeline'

function SettingTariffItem({ tarifInfo, teamData, disable, active = false }) {
    const { name, maxProjects, maxUsers, cost, maxFilesVolume } = tarifInfo
    const [isVeiwPopup, setVeiwPopup] = useState(false)

    return (
        <div
            className={
                'card card-upgrade card-upgrade--basic w-100' +
                (disable ? ' card-deactivated' : '') +
                (active ? ' card-active' : '')
            }
        >
            <div className="p-6 pt-9 pb-9">
                <p className="text-large font-bold">{name}</p>
                <div className="mt-4">
                    <p className="text-h2 font-medium">
                        {cost && conversionCurrency(cost)}
                        <span className="ms-4 text-big font-medium">₽</span>
                    </p>
                    {/* <p className="font-medium text-medium mt-2 mb-2 card-upgrade--promotion">МЕСЯЦ БЕСПЛАТНО</p> */}
                    <p>Для участника при оплате за год</p>
                </div>
            </div>
            <div className="app-border mt-3 w-100">&nbsp;</div>
            <div className="p-6 pt-9 pb-9">
                <button
                    className={'btn btn-blue-dark w-100' + (disable ? ' button-deactivated' : '')}
                    onClick={() => setVeiwPopup(true)}
                >
                    {tarifInfo?.name === 'Free' && 'Бесплатно'}
                    {tarifInfo?.name !== 'Free' && 'Улучшить'}
                    
                </button>
                <ul className="app-list app-list--orange mt-7">
                    <li className="position-relative text-medium">До {maxUsers} участников</li>
                    <li className="position-relative text-medium mt-6">До {maxProjects} проектов</li>
                    <li className="position-relative text-medium mt-6">
                        Хранилище {maxFilesVolume / 1024 / 1024} Мб на команду
                        <span className="d-block color-grey-secondary mt-2 text-little">Файлы и скриншоты до 5 Мб</span>
                    </li>
                    <li className="position-relative text-medium mt-6">
                        История действий с задачами за последнюю неделю
                    </li>
                    <li className="position-relative text-medium mt-6">Создание приватных пространств</li>
                </ul>
            </div>

            <ChoiceTariffPopup isView={isVeiwPopup} setView={setVeiwPopup} tarifInfo={tarifInfo} teamData={teamData} />
        </div>
    )
}

export default SettingTariffItem
