import { createSlice } from '@reduxjs/toolkit'

export const TeamSlice = createSlice({
    name: 'teams',
    initialState: {
        teams: [],
        projects: [],
        isViewModal: false,
    },
    reducers: {
        setInitialData: (state, action) => {
            const { projects, teams } = action.payload

            state.teams = teams
            state.projects = projects
        },
        setTeams: (state, action) => {
            state.teams = action.payload
            //state.projects = action.payload.reduce((accum, team) => [...accum, ...team.projects], [])
        },
        setProjects: (state, action) => {
            const { projects } = action.payload

            state.projects = projects
        },
        addTeams: (state, action) => {
            state.teams = [...state.teams, action.payload]
            state.projects = [...state.projects, ...action.payload.projects]
        },
        addProject: (state, action) => {
            const project = action.payload

            state.projects = [...state.projects, project]

            state.teams = state.teams.map(team => {
                if (team.id !== project.teamId) return team

                return {
                    ...team,
                    projects: [...team.projects, project],
                }
            })
        },
        deleteProjectStore: (state, action) => {
            const { projectId } = action.payload

            state.projects = state.projects.filter(project => project.id !== projectId)
        },
        changeTeams: (state, action) => {
            // to delete
            const { data, type } = action.payload

            if (type === 'project') {
                state.projects = state.projects.map(project => {
                    if (project.id === data.id) {
                        return data
                    }
                    return project
                })
            }

            if (type === 'team') {
                state.teams = state.teams.map(team => {
                    if (team.id === data.id) {
                        return data
                    }
                    return team
                })
            }

            return state
        },
        updateProject: (state, action) => {
            const data = action.payload

            state.projects = state.projects.map(project => {
                if (project.id === data.id) {
                    return {
                        ...project,
                        ...data,
                    }
                }
                return project
            })
        },
        setViewModal: (state, action) => {
            state.isViewModal = action.payload
        },
        addColumn: (state, action) => {
            const { projectId, column } = action.payload

            state.projects = state.projects.map(project => {
                if (project.id !== projectId) return project

                return {
                    ...project,
                    statusesResponse: {
                        ...project.statusesResponse,
                        statuses: [...project.statusesResponse.statuses, column],
                    },
                }
            })
        },
    },
})

export const {
    setTeams,
    addTeams,
    addProject,
    changeTeams,
    setViewModal,
    addColumn,
    setProjects,
    setInitialData,
    deleteProjectStore,
    updateProject,
} = TeamSlice.actions

export default TeamSlice.reducer
