import { TextAndInput } from '../Elements/MiniComponents'

function ListSubtask({ subtask, updateTask }) {
    return (
        <div className="app-table__row d-flex align-items-center justify-content-between w-100">
            <div className="app-table__col w-100">
                <div className="app-table__content w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="p-6"></div>
                        <div className="p-5"></div>
                        {/* {{> app-tooltip/tooltip-status-work }} */}
                        {/* <p className="font-medium ms-4">{ subtask.title }</p> */}
                        <TextAndInput
                            text={subtask.title}
                            preholder="Введите название подзадачи"
                            addClass="font-medium ms-4"
                            update={(text) => updateTask({ id: subtask.id, title: text })}
                        />
                        {/* <div className="app-table__hidden ms-2">
                            {{> app-tooltip/tooltip-menu2 }}
                        </div> */}
                    </div>
                    {/* <div className="app-table__hidden">
                        {{> app-tooltip/tooltip-subtitle2 }}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ListSubtask
