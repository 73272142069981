import { useSelector } from 'react-redux'
import { ReactComponent as IconEmptyAvatar } from '../../assets/menu/emptyAvatar.svg'

function IconAvatar({ userId, addClass = '', onClick = () => {} }) {
    const user = useSelector(state => state.users.imageUsers.find(user => user.userId === userId))

    return (
        <>
            {user && user.imgUrl && <img className={addClass} src={user.imgUrl} onClick={onClick} alt='user avatar' />}
            {(!user || !user.imgUrl) && (
                <IconEmptyAvatar
                    className="app-content__avatar flex-center cursor-pointer mt-1 mb-1 ms-1 me-1 js--dropdown-open"
                    onClick={onClick}
                />
            )}
        </>
    )
}

export default IconAvatar
