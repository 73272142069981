import { ReactComponent as IconPlus } from '../../assets/kanban/plus4.svg'
import { useDispatch } from 'react-redux'
import { setViewModal } from '../../store/Teams'

function CreateColumn() {
    const dispatch = useDispatch()
    
    return (
        <>
            <button
                className="btn btn-blue d-flex align-items-center btn-height-max pt-10 pb-10 ps-4 pe-4 text-primary ms-4"
                data-show-modal="overlay-status"
                onClick={() => dispatch(setViewModal('createColumn'))}
            >
                <span className="svg-block me-2">
                    <IconPlus />
                </span>
                Статус
            </button>
        </>
    )
}

export default CreateColumn



