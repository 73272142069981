import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { requests } from '../../requests'
import { deleteFileTask } from '../../pipeline/FilePipeLines'
import { ReactComponent as IconDelete } from '../../assets/task/delete.svg'

function FileTask({ fileId, name }) {
    const [imgUrl, setImgUrl] = useState(null)
    const [viewDelete, setViewDelete] = useState(false)
    const { taskId } = useParams()

    useEffect(() => {
        requests.getImageFilePreview(taskId, fileId, image => {
            setImgUrl(image)
        })
    }, [taskId, fileId])

    return (
        <div
            className="app-file d-flex flex-column align-items-center"
            style={{
                zIndex: '10',
            }}
            onMouseEnter={() => setViewDelete(true)}
            onMouseLeave={() => setViewDelete(false)}
        >
            {viewDelete && (
                <div className="delete-file">
                    <IconDelete
                        className="cursor-pointer z-index-100"
                        onClick={() => deleteFileTask(Number(taskId), fileId)}
                    />
                </div>
            )}
            <div
                className="app-file__image p-1 file-container"
                onClick={() => {
                    requests.downloadFile(taskId, fileId, name)
                }}
            >
                <img src={imgUrl} alt="file" className="cursor-pointer" />
            </div>

            <p
                className="text-small"
                style={{
                    zIndex: '25',
                    maxWidth: '112px',
                }}
            >
                {name}
            </p>
        </div>
    )
}

export default FileTask
