export const LOG = (e) => console.log(e)

export const PARAMS = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}

export const HOST = process.env.REACT_APP_BACKEND_HOST.trim()

export const PATHS = {
    register: '/join',
    login: '/login',
    auth: '/auth',
    resetPass: '/join/reset',
    changePass: '/join/change',
    tasks: '/tasks',
    getTaskById: '/tasks/task',
    addFileToTask: '/tasks/file',
    asSubTask: '/tasks/subTask',
    projects: '/projects',
    team: '/team',
    purchase: '/purchase',
    payment: '/payment',
}