import { errorNotification } from "../pipeline/notificationPipeline"
import { sendRequest } from "./fetch"
import { LOG, PARAMS, PATHS } from "./lib"


export const logInRequest = {
    logIn: (email, password, callbackOn = LOG, callbackOff = errorNotification) => {
        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify({ email, password }),
        }


        sendRequest(PATHS.login, params, callbackOn, callbackOff)
    },
    register: (registrateData, callbackOn = LOG, callbackOff = errorNotification) => {
        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify(registrateData),
        }

        sendRequest(PATHS.register, params, callbackOn, callbackOff)
    },
    resetPassword: (email, callbackOn = LOG, callbackOff = errorNotification) => {
        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify({ email }),
        }

        sendRequest(PATHS.resetPass, params, callbackOn, callbackOff)
    }
}