import { ReactComponent as IconPlus } from '../../assets/kanban/plus2.svg'
import { ReactComponent as IconDelete } from '../../assets/task/delete.svg'
import { TextAndInput } from '../Elements/MiniComponents'
import { useParams } from 'react-router-dom'
import { updateStatusProject, deleteColumn } from '../../pipeline/BasicPipelines'

function TitleColumnKanban({ setOpenAddTask, isOpenAddTask, title, color, id }) {
    const { projectId } = useParams()

    return (
        <div className="app-boards__top d-flex align-items-center justify-content-between mt-4 mb-4">
            <div className="d-flex align-items-center mWidth-220">
                <div className="app-boards__status me-1" style={{ background: color }}></div>
                {/* <p className="text-large js--board-hide"> {title} </p> */}
                <TextAndInput
                    text={title}
                    preholder={'Введите название колонки'}
                    addClass="text-large js--board-hide"
                    update={data => updateStatusProject(Number(projectId), { statusId: id, title: data })}
                    isCheckEnter
                />
            </div>
            <div className="d-flex align-items-center svg-block">
                <div 
                    className="me-1 cursor-pointer js--board-btn"
                    onClick={() => deleteColumn(Number(projectId), id)}
                >
                    <IconDelete />
                </div>
                <div
                    className="me-1 cursor-pointer js--board-hide js--board-search-btn"
                    onClick={() => setOpenAddTask(!isOpenAddTask)}
                >
                    <IconPlus />
                </div>
            </div>
        </div>
    )
}

export default TitleColumnKanban
