import store from '../store'
import { setToken } from '../requests/fetch'
import { requests } from '../requests'
import { setInitialData } from '../store/Teams'
import { setUsers } from '../store/Users'
import { deleteDuplicateObject } from './helpers'
import { setUserAvatar, setClientInfo } from '../store/Users'


export const initWorkArea = (dispatch, on = () => {}) => {
    const initData = {
        teams: null,
        projects: null,
    }

    const checkApply = []

    const check = (type, data) => {
        initData[type] = data
        checkApply.push(type)

        for (const prop in initData) {
            if (initData.hasOwnProperty(prop) && !checkApply.find(item => item === prop)) {
                return
            }
        }

        dispatch(setInitialData(initData))
        on && on(initData)
    }

    requests.getUserTeam(res => {
        check('teams', res.teams)

        const users = res.teams.reduce((sum, item) => {
            return [...sum, ...item.membersInfo]
        }, [])

        dispatch(setUsers(deleteDuplicateObject(users, 'userId')))

        //on && on(res.teams?.[0])
    })

    requests.getUserProjects(res => check('projects', res.projects))

    requests.getClientInfo(data => dispatch(setClientInfo(data)))
}

export function navigateTofirstTeam (navigate) {
    const state = store.getState()
    const teamId = state.teams?.teams?.[0]?.id

    if (!teamId) return

    navigate(`/team/${teamId}`, { replace: true })
}

export function logInPipeline(email, password, callbackOn, callbackOff) {
    requests.logIn(
        email,
        password,
        res => {
            console.log(res.token)
            setToken(res.token)
            callbackOn && callbackOn()
        },
        callbackOff
    )
}

export function registrationPipeline(registrateData, callbackOn, callbackOff) {
    requests.register(registrateData, callbackOn, callbackOff)
}

export function loadingUserAvatar(userId) {
    async function reqToUserAvatar(res) {
        const dispatch = store.dispatch

        const imgUrl = await imageToBlob(res)
        dispatch(setUserAvatar({ userId, imgUrl }))
    }

    requests.getUserAvatar(userId, reqToUserAvatar)
}

async function imageToBlob(res) {
    if (res.blob) {
        const imageBlob = await res.blob()
        const imageObjectURL = URL.createObjectURL(imageBlob)
        console.log(imageObjectURL, 'imageObjectURL', imageBlob)

        return imageObjectURL
    } else {
        return res
    }
}
