import { createSlice } from '@reduxjs/toolkit'
import { loadingUserAvatar } from '../pipeline/InitPipelines'

export const UserSlice = createSlice({
    name: 'users',
    initialState: {
        clientInfo: {
            name: '',
            userId: null,
            email: null,
        },
        users: [],
        imageUsers: [],
        openSettingProfile: false,
    },
    reducers: {
        setClientInfo: (state, action) => {
            const { name, userId, email } = action.payload

            let newClientInfo = { ...state.clientInfo }

            if (typeof name !== 'undefined') newClientInfo.name = name

            if (userId) newClientInfo.userId = userId

            if (email) newClientInfo.email = email

            state.clientInfo = newClientInfo


            state.users = state.users.map(user => {
                if (userId !== user.userId) return user

                let newDataUser = { ...user }

                if (name) newDataUser.name = name

                if (userId) newDataUser.userId = userId

                if (email) newDataUser.email = email

                return newDataUser
            })
        },
        setUsers: (state, action) => {
            const users = action.payload
            state.users = users

            users.forEach(user => {
                if (!(state.imageUsers.find(item => user.userId === item.userId))) {
                    loadingUserAvatar(user.userId)
                }
            });
        },
        addUser: (state, action) => {
            state.users = [...state.users, action.payload]

            loadingUserAvatar(action.payload?.userId)
        },
        changeUser: (state, action) => {
            const { type, userId, data } = action.payload

            state.users = state.users.map(user => {
                if (userId !== user.userId) return user

                return {
                    ...user,
                    [type]: data,
                }
            })
        },
        setUserAvatar: (state, action) => {
            const { imgUrl, userId } = action.payload

            state.imageUsers = [ ...state.imageUsers.filter(user => user.userId !== userId), {
                userId,
                imgUrl
            }]
        },
        // changeViewSettingProfile: (state, action) => {
        //     state.openSettingProfile = action.payload
        // }
    },
})

export const { setClientInfo, setUsers, addUser, changeUser, setUserAvatar } = UserSlice.actions

export default UserSlice.reducer

//user type

//name
//email
//userId
//imgUrl
