import { TextAndInput } from './MiniComponents'

import { useState } from 'react'

function SubTaskProperties({ title, isDone = false, update }) {
    const [inputCheck, setinputCheck] = useState(isDone)
    
    return (
        <div className="app-subtask d-flex align-items-center justify-content-start pt-2 pb-2 w-100">
            <div
                className="app-checkbox cursor-pointer"
                onClick={() => {
                    setinputCheck(!inputCheck)
                    update({ checked: !inputCheck })
                }}
            >
                <input className="app-checkbox__input cursor-pointer" type="checkbox" checked={inputCheck} />
                <label className="font-medium cursor-pointer"></label>
            </div>
            <TextAndInput
                text={title}
                addClass="font-medium cursor-pointer shadow-input minW-200"
                preholder="Введите название подзадачи"
                update={title => update({ title })}
            />
            {/* <div className="svg-block d-flex flex-shrink ms-2">
                <DescriptionCard description={description} />
            </div> */}
        </div>
    )
}

export default SubTaskProperties
