import { useState } from 'react'
import { createPortal } from 'react-dom'
import { ReactComponent as IconClose } from '../../assets/menu/close4.svg'

function PopupCreateNewEntity({ isView, title, label, placeholder, buttonTitle = 'Создать', onCreate, onClose }) {
    const [text, setText] = useState('')

    const addClass =
        'overlay overlay-add d-flex align-items-center justify-content-center w-100 h-100'
         + (isView ? ' overlay-show' : '')

    return createPortal(
        <>
            {true && (
                <div className={addClass}>
                    <div className="overlay-wrap position-relative">
                        <div className="d-flex justify-content-end w-100">
                            <div
                                className="overlay-close overlay-close--relative cursor-pointer flex-center js--overlay-close"
                                onClick={() => onClose()}
                            >
                                <IconClose />
                            </div>
                        </div>
                        <div className="overflow-hidden position-relative">
                            <div className="overlay-wrap__content overlay-scroll d-flex flex-column">
                                <div className="ps-7 pe-7 mb-9">
                                    <p className="text-big font-medium">{title}</p>
                                </div>
                                <div className="p-7 pt-9">
                                    <div className="input-basic w-100 ">
                                        {label && <label className="font-medium">{label}</label>}
                                        <input
                                            type="text"
                                            className="w-100 h-100 mt-4"
                                            placeholder={placeholder}
                                            value={text}
                                            onChange={e => setText(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary mt-6 w-100"
                                        onClick={() => {
                                            if (!isView) return 

                                            onCreate(text)
                                            setText('')
                                        }}
                                    >
                                        {buttonTitle}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>,
        document.body
    )
}

export default PopupCreateNewEntity
