//import DescriptionCard from '../Elements/DescriptionCard'
import TagsCard from '../Elements/TagsCard'
import PriorityCard from '../Elements/PriorityCard'
import DeadlineCard from '../Elements/DeadlineCard'
import IconSubTaskCard from '../Elements/IconSubTaskCard'
import ExecutorTask from './ExecutorTask'
import CardSubtasks from './CardSubtasks'

import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { requests } from '../../requests'

function KanbanCard({ card, updateCard, allTags, index, columnInfo, projectUsers }) {
    const { projectId } = useParams()
    let navigate = useNavigate()

    const [isHover, setShowHovere] = useState(false)
    const [isSubtaskView, setSubTasksView] = useState(false)
    const users = useSelector(state => state.users.users)

    const update = (change, settings) => {
        const dataChange = {
            id: card.id,
            ...change,
        }

        updateCard(dataChange, settings)
    }

    const createSubTask = () => {
        setSubTasksView(true)

        requests.createSubtask(projectId, card.id, { title: '' }, res => {
            const newTask = {
                ...card,
                subAssignmentsResponse: [...card.subAssignmentsResponse, res],
            }

            update(newTask, { updateBackend: false })
        })
    }

    return (
        <div
            className="app-card js--subtask js--addtask"
            onMouseEnter={() => setShowHovere(true)}
            onMouseLeave={() => setShowHovere(false)}
            onClick={() => navigate(`/project/${projectId}/${card.id}`)}
            style={{ borderLeft: `4px solid ${columnInfo.color}` }}
        >
            <div className="ps-4 pe-4 pt-4 position-relative js--addtask-card mb-2">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="app-card__title font-medium">{card.title}</p>
                    <div className="d-flex align-items-center">
                        {/* <DescriptionCard description={card.description} /> */}
                        {/* <IconUser /> */}
                        <div className="d-flex align-items-center overlay-wrap__content-avatar">
                            <ExecutorTask
                                selectUsers={(card.executors && users.filter(user => card.executors.includes(user.userId))) || []}
                                users={(card.executors && projectUsers.filter(user => !card.executors.includes(user.userId))) || projectUsers}
                                taskId={card.id}
                            />
                        </div>
                    </div>
                </div>
                <div className="app-card__status d-flex align-items-center flex-wrap w-100">
                    <div className="app-card__subtask-wrapper js--addtask-subtask overflow-hidden">
                        <div className="app-card__subtask d-flex align-items-center svg-block me-2 cursor-pointer js--subtask-btn">
                            {/* {{> app-tooltip/tooltip-subtask }} */}
                            <IconSubTaskCard
                                subTasks={card.subAssignmentsResponse}
                                changeView={() => setSubTasksView(!isSubtaskView)}
                                createSubTask={createSubTask}
                            />
                        </div>
                    </div>
                    <PriorityCard priority={card.importanceScale} update={update} />
                    {/* {{> app-dropdown/dropdown-status2 }} */}
                    {card.deadline && (
                        <div className="ms-3 pt-10 pb-10 ps-1 pe-2">
                            <DeadlineCard deadline={card.deadline} update={update} />
                        </div>
                    )}

                    {card.tags.map(tag => (
                        <TagsCard key={tag.id} tag={tag} allTags={allTags} update={update} />
                    ))}

                    {/* {['tets', 'tets', 'tets', 'tets','tets' ,'tets' ,'tets' ,'tets', 'tets','tets','tets'].map(tag => <TagsCard tag={'tets'}/>)} */}
                </div>
                {(isHover || isSubtaskView) && (
                    <div className="app-card__add-task svg-block js--addtask-action js--subtask-hidden mb-2">
                        <div className="app-border2 mb-2 mt-4"></div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="app-card__param flex-center">
                                    {!card.deadline && (
                                        <DeadlineCard deadline={card.deadline} update={update} typeView={'icon'} />
                                    )}
                                </div>
                                <div className="app-card__param flex-center">
                                    {!card.importanceScale && (
                                        <PriorityCard
                                            priority={card.importanceScale}
                                            update={update}
                                            typeView={'icon'}
                                        />
                                    )}
                                </div>
                                <div className="app-card__param flex-center">
                                    {<TagsCard allTags={allTags} update={update} />}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <CardSubtasks subTasks={card.subAssignmentsResponse} isShow={isSubtaskView} update={data => {
                requests.changeSubtask(projectId, data.id, data)

                const newTask = {
                    ...card, 
                    subAssignmentsResponse: card.subAssignmentsResponse.map(item => {
                        if (item.id === data.id) {
                            return {
                                ...item, 
                                ...data,
                            }
                        }

                        return item
                    })
                }

                update(newTask, { updateBackend: false })
            }} />
        </div>
    )
}

export default KanbanCard
