import CardSubtask from '../Elements/CardSubtask'

function CardSubtasks({ isShow, subTasks, update }) {

    const styleAdd = 'app-card__subtasks-wrapper js--subtask-content w-100' + (isShow ? ' active' : ' max-height-0')
    
    return (
        <div className={styleAdd} onClick={e => e.stopPropagation()}>
            {subTasks &&
                subTasks.map(task => (
                    <CardSubtask
                        key={task.id}
                        id={task.id}
                        title={task.title}
                        checkbox={task.checked}
                        update={update}
                    />
                ))}
        </div>
    )
}

export default CardSubtasks
