import LeftMenuItem from '../Elements/LeftMenuItem'
import PopupCreateNewEntity from '../Elements/PopupCreateNewEntity'

import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { createTeam, createProject } from '../../pipeline/CreatePipelines'
import { useState } from 'react'

function LeftMenu({ type }) {
    const navigate = useNavigate()
    const { projectId, teamId } = useParams()
    const { data, redirect, create, createInfoField, addClassItem, addClassItemBackground } = createConfigMenu(type, navigate)

    let viewItems = useSelector(state => state.teams[data])
    const [isViewCreatePopup, setViewCreatePopup] = useState(false)

    const activeId = chechActive(type, { projectId, teamId })

    if (type === 'projects') {
        //to rewrite
        const project = viewItems.find(pr => pr.id === Number(projectId))
        const teamId = project?.teamId

        viewItems = viewItems.filter(pr => pr.teamId === teamId)
    }

    return (
        <>
            <div className="position-relative">
                <div className="app-menu-width"></div>
                <div className="app-menu d-flex flex-column flex-shrink align-items-center position-relative pt-10">
                    {viewItems.map(({ id, title }) => (
                        <LeftMenuItem
                            key={id}
                            id={id}
                            title={title}
                            onRedirect={redirect}
                            isActive={activeId === id}
                            addClassStyle={addClassItem}
                            addClassStyleBackground={addClassItemBackground}
                        />
                    ))}

                    <LeftMenuItem
                        isCreate={true}
                        onRedirect={() => setViewCreatePopup(true)}
                        addClassStyle={addClassItem}
                        addClassStyleBackground={addClassItemBackground}
                    />
                </div>
            </div>

            <PopupCreateNewEntity
                isView={isViewCreatePopup}
                title={createInfoField.title}
                label={createInfoField.label}
                placeholder={createInfoField.placeholder}
                onClose={() => setViewCreatePopup(false)}
                onCreate={data => {
                    setViewCreatePopup(false)
                    create(data, viewItems?.[0]?.teamId)
                }}
            />
        </>
    )
}

function chechActive(type, { teamId, projectId }) {
    switch (type) {
        case 'projects':
            return Number(projectId)

        case 'teams':
            return Number(teamId)

        default:
            return ''
    }
}

const createConfigMenu = (type = 'teams', navigate) => {
    if (type === 'teams') {
        return {
            data: 'teams',
            redirect: id => navigate(`/team/${id}`),
            create: title => createTeam({ data: { title }, on: res => navigate(`/team/${res.id}`) }),
            createInfoField: {
                title: 'Создание новой команды',
                label: 'Название команды',
                placeholder: 'Название команды',
            },
            addClassItem: 'pt-10 pb-10',
            addClassItemBackground: '',
        }
    }

    if (type === 'projects') {
        return {
            data: 'projects',
            redirect: id => navigate(`/project/${id}`),
            create: (name, teamId) =>
                createProject({
                    data: {
                        name,
                    },
                    teamId,
                    on: res => navigate(`/project/${res.id}`),
                }),
            createInfoField: {
                title: 'Создание нового проекта',
                label: 'Название проекта',
                placeholder: 'Название проекта',
            },
            addClassItem: 'pt-10 pb-10',
            addClassItemBackground: ' circle',
        }
    }
}

export default LeftMenu
