import { HOST } from './lib'

export let token = checkInitToken()

export const setToken = tok => {
    token = tok
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('timeSaveToken', Math.round(Date.now() / 1000))
}

export const sendRequest = (path, params, callbackOn, callbackOff, isNoReturnJson) => {
    try {
        token && (params['headers']['Authorization'] = 'Bearer ' + token)

        fetch(HOST + path, params)
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    isNoReturnJson ? callbackOn() : response.json().then(callbackOn)
                } else if (response.status === 403) {
                    response.json().then(e => callbackOff(e.message))
                } else if (response.status === 202) {
                    isNoReturnJson
                        ? callbackOn() // need add set token
                        : response.json().then(res => {
                              callbackOn(res)
                          })
                } else {
                    const contentType = response.headers.get('content-type')

                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return response.json().then(e => callbackOff(e.message))
                    } else {
                        return response.text().then(e => callbackOff(e))
                    }
                }
            })
            .catch(err => {
                console.log('ERROR', err)
                callbackOff()
            })
    } catch {
        console.log('ERROR')
    }
}

function checkInitToken() {
    const token = window.localStorage.getItem('token')

    if (token) {
        const time = window.localStorage.getItem('timeSaveToken')

        const liveToken = time && Math.round(Date.now() / 1000) - time < 86400 ? true : false

        if (!liveToken) return null
    }

    return token
}

export function deleteSaveToken() {
    const tokenStorage = window.localStorage.getItem('token')

    token = null

    if (!tokenStorage) return

    window.localStorage.removeItem('token')
    window.localStorage.removeItem('timeSaveToken')
}