import KanbanBoar from './KanbanBoard'
import ListBoard from './ListBoard'
import { useState } from 'react'

function BoardSpace() {
    const [typeView, setTypeView] = useState('kanban')

    return (
        <div className="app-tabs js--tabs w-100">
            <div className="app-tabs__top d-flex align-items-center ms-4">
                <p
                    className={
                        'd-flex align-items-center svg-block cursor-pointer me-2 js--tabs-btn' +
                        (typeView === 'list' ? ' active' : '')
                    }
                    onClick={() => setTypeView('list')}
                >
                    List
                </p>
                <p
                    className={
                        'd-flex align-items-center svg-block cursor-pointer js--tabs-btn' +
                        (typeView === 'kanban' ? ' active' : '')
                    }
                    onClick={() => setTypeView('kanban')}
                >
                    Kanban
                </p>
            </div>
            <div className="app-tabs__contents w-100">
                {typeView === 'kanban' && <KanbanBoar />}
                {typeView === 'list' && <ListBoard />}
            </div>
        </div>
    )
}

export default BoardSpace
