import { createPortal } from 'react-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchInStrings } from '../../pipeline/helpers'
import { requests } from '../../requests'
import { changeTeams } from '../../store/Teams'
import { deleteUserOnProject, addUserOnProject, deleteProject } from '../../pipeline/BasicPipelines'
import AvatarList from '../Elements/AvatarList'
import ProjectUserItem from '../Elements/ProjectUserItem'
import ProjectAvatar from '../Elements/ProjectAvatar'

import { ReactComponent as IconClose } from '../../assets/menu/close4.svg'
import { useNavigate } from 'react-router-dom'

function SettingProjectPopup({ isView, changeView, projectId, imgUrl, changeImage }) {
    const project = useSelector(state => state.teams.projects.find(pr => pr.id === Number(projectId)))
    const team = useSelector(state => state.teams.teams.find(te => te.id === project?.teamId))
    const clientInfo = useSelector(state => state.users.clientInfo)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [nameProject, setNameProject] = useState((project && project.title) || '')
    const [textFilter, setInputText] = useState('')

    useEffect(() => {
        project && setNameProject(project.title)
    
      return () => {}
    }, [ project ])
    

    if (!project || !team) return <></>

    const addUsers = team.membersInfo.filter(user => !project.userInfos.find(us => us.userId === user.userId))

    return createPortal(
        // NEED TO REWORK!!!
        <>
            {isView && (
                <div className="overlay overlay-settings d-flex align-items-center justify-content-center w-100 h-100 overlay-show">
                    <div className="overlay-wrap overlay-scroll position-relative">
                        <div className="d-flex justify-content-end w-100">
                            <div className="overlay-close cursor-pointer flex-center js--overlay-close">
                                <IconClose onClick={() => changeView(false)} />
                            </div>
                        </div>
                        <div className="overlay-wrapper  d-flex flex-column">
                            <div className="p-9 ps-7 pe-7">
                                <p className="text-big font-medium">Настроки проекта</p>
                            </div>
                            <div className="app-border10"></div>
                            <div className="p-7 d-flex justify-content-between">
                                <div className="d-flex flex-column justify-content-between w-100 me-8">
                                    <div className="">
                                        <p className="font-medium text-medium mb-4">Название проекта</p>
                                        <div className="input-basic">
                                            <input
                                                type="text"
                                                placeholder="Введите название проекта"
                                                className="ps-6 pe-6 w-100 h-100"
                                                value={nameProject}
                                                onChange={e => setNameProject(e.target.value)}
                                                onBlur={() => 
                                                    requests.changeProject({ projectId, title: nameProject }, res =>
                                                        dispatch(
                                                            changeTeams({
                                                                type: 'project',
                                                                data: res,
                                                            })
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-medium mt-7">Участники проекта</p>
                                        <div className="d-flex justify-content-between align-items-center w-100 mt-4">
                                            <div className="d-flex align-items-center">
                                                <p className="border-grey2 border-radius-4 pt-2 pb-2 ps-6 pe-6 me-2">
                                                    {project.userInfos.length}
                                                </p>
                                                <AvatarList users={project.userInfos} onClick={() => {}} length={12} />
                                            </div>
                                        </div>
                                        <div className="input-search d-flex align-items-center svg-block w-100 mt-4 p-4">
                                            <input
                                                type="text"
                                                placeholder="Поиск участника"
                                                className="w-100 h-100 ms-2"
                                                value={textFilter}
                                                onChange={e => setInputText(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-2 me-4 d-flex flex-column">
                                    <p className="font-medium text-medium mb-4">Аватар проекта</p>
                                    <ProjectAvatar
                                        imgUrl={imgUrl}
                                        name={project.title}
                                        id={project.id}
                                        on={changeImage}
                                    />
                                    {/* {{> app-upload/upload-avatar }} */}
                                </div>
                            </div>
                            <div className="app-border10 w-100">&nbsp;</div>
                            <div className="p-7 pb-0">
                                <p className="text-medium">Назначены на проект</p>
                                <div className="mt-1">
                                    {searchInStrings(textFilter, project.userInfos, 'name').map(({ userId, name }) => (
                                        <ProjectUserItem
                                            key={userId}
                                            userId={userId}
                                            name={name}
                                            typeButton={project.moderatorId === clientInfo.userId && 'delete'}
                                            onClick={() => deleteUserOnProject({ userId }, project.id)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="p-7 pt-4">
                                {addUsers[0] && (
                                    <div className="mt-6">
                                        <p className="text-medium">Не назначены на проект</p>
                                        <div className="mt-1">
                                            {searchInStrings(textFilter, addUsers, 'name').map(user => (
                                                <ProjectUserItem
                                                    key={user.userId}
                                                    userId={user.userId}
                                                    name={user.name}
                                                    typeButton={project.moderatorId === clientInfo.userId && 'create'}
                                                    onClick={() => addUserOnProject(user, project.id)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {/* </div> */}
                            </div>
                            <div className="app-border10 w-100">&nbsp;</div>
                            {project.moderatorId === clientInfo.userId && (
                                <div className="p-7">
                                    <p
                                        className="color-error text-underline cursor-pointer"
                                        onClick={() => deleteProject(project.id, () => navigate(`/team/${team.id}`))}
                                    >
                                        Удалить проект
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>,
        document.body
    )
}

export default SettingProjectPopup
