import LeftMenuItem from './LeftMenuItem'

function TeamDropdownSelectItem({ id, name, redirect, addClassStyle = '' }) {
    return (
        <div className={'d-flex align-items-center ' + addClassStyle} onClick={redirect}>
            <LeftMenuItem id={id} title={name} onRedirect={redirect} />
            <p className="font-medium ms-4">{name}</p>
        </div>
    )
}

export default TeamDropdownSelectItem
