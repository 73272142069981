import IconAvatar from './IconAvatar'

function AvatarList({ users, onClick = () => {}, length = 6 }) {
    return (
        <div className="app-avatar__list d-flex align-items-center me-2" onClick={onClick}>
            {truncateArray(users, length).map(({ userId }) => (
                <IconAvatar key={userId} userId={userId} addClass="app-avatar__list-item" />
            ))}

            {/* <div className="app-avatar__list-item">
                <div className="circle-36 text-little font-medium color-grey3 bg-grey2 flex-center">+6</div>
            </div>  */}
        </div>
    )
}

export default AvatarList

function truncateArray(arr, maxLength) {
    if (arr.length > maxLength) {
        return arr.slice(0, maxLength)
    }
    return arr
}
