import { useState } from 'react'
import { getParticipantsLabelRussian } from '../../pipeline/helpers'

function SelectList({ type, value, onChange, minValue = 1 }) {
    const config = config_select(type)
    const [isView, setView] = useState(false)

    const addClass =
        'app-select__content w-100 mt-2 bg-white border-radius-10 overflow-hidden js--app-select-content scroll-y' +
        (isView ? ' select__checkboxShow' : '')

    return (
        <div className="app-select w-100 h-100 bg-white border-radius-10 position-relative js--app-select">
            <div
                onClick={() => setView(!isView)}
                className="app-select__title d-flex align-items-center w-100 position-relative cursor-pointer ps-5 text-primary js--app-select-title"
            >
                {config?.selection.find(item => item.key === value)?.title}
            </div>
            <div className={addClass}>
                {config?.selection.map(item => {
                    if (item.key < minValue) return <></>

                    return (
                        <label
                            className="d-flex align-items-center w-100 p-3 pt-2 pb-2 cursor-pointer"
                            onClick={() => {
                                setView(false)
                                onChange(item.key)
                            }}
                            key={item.key}
                        >
                            {item.title}
                        </label>
                    )
                })}
            </div>
        </div>
    )
}

const config_select = type => {
    switch (type) {
        case 'months':
            return {
                selection: [
                    {
                        title: '1 месяц',
                        key: 1,
                    },
                    {
                        title: '2 месяца',
                        key: 2,
                    },
                    {
                        title: '3 месяца',
                        key: 3,
                    },
                    {
                        title: '4 месяца',
                        key: 4,
                    },
                    {
                        title: '5 месяцев',
                        key: 5,
                    },
                    {
                        title: '6 месяцев',
                        key: 6,
                    },
                    {
                        title: '7 месяцев',
                        key: 7,
                    },
                    {
                        title: '8 месяцев',
                        key: 8,
                    },
                    {
                        title: '9 месяцев',
                        key: 9,
                    },
                    {
                        title: '10 месяцев',
                        key: 10,
                    },
                ],
            }

        case 'users':
            return {
                selection: createUsersSelection(),
                // [
                //     {
                //         title: '1 участник',
                //         key: 1,
                //     },
            }

        default:
            break
    }
}

export default SelectList

function createUsersSelection(count = 100) {
    const usersSelection = []

    for (let index = 0; index < count; index++) {
        usersSelection.push({
            key: index + 1,
            title: `${index + 1} ${getParticipantsLabelRussian(index + 1)}`,
        })
    }

    return usersSelection
}
