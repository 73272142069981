import { configureStore } from '@reduxjs/toolkit'
import users from './Users'
import teams from './Teams'
import tasks from './TaskProperties'
import notification from './Notification'

const store = configureStore({
  reducer: {
    users,
    teams,
    tasks,
    notification
  },
})

export default store