import { createSlice } from '@reduxjs/toolkit'

export const TaskSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: [],
        tags: [],
        teamName: null,
        propjectName: null,
        activiteLog: null,
    },
    reducers: {
        setStatuses: (state, action) => {
            state.statuses = action.payload
        },
        addStatuses: (state, action) => {
            state.statuses = [...state.statuses, action.payload]
        },
        setTeamName: (state, action) => {
            state.teamName = action.payload
        },
        setPropjectName: (state, action) => {
            state.propjectName = action.payload
        },
        setTasks: (state, action) => {
            state.tasks = action.payload
        },
        deleteTask: (state, action) => {
            const { taskId } = action.payload

            state.tasks = state.tasks.filter(task => task.id !== taskId)
        },
        setTags: (state, action) => {
            state.tags = action.payload
        },
        changeTag: (state, action) => {
            const newTag = action.payload

            state.tags = state.tags.map(tag => {
                if (newTag.id !== tag.id) return tag

                return {
                    ...tag,
                    ...newTag
                }
            })
        },
    },
})

export const { setStatuses, addStatuses, setTeamName, setPropjectName, setTasks, setTags, changeTag, deleteTask } = TaskSlice.actions

export default TaskSlice.reducer
