import TaskDropduwnStatus from '../Elements/TaskDropduwnStatus'
import ExecutorTask from './ExecutorTask'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReactComponent as IconDelete } from '../../assets/task/delete.svg'

function TaskInfo({ statusInfo, status, createTime, update, executors, projectUsers, changeColumn, onDelete }) {
    const users = useSelector(state => state.users.users)
    const { taskId } = useParams()

    return (
        <div className="overlay-wrap__content-top d-flex align-items-center w-100">
            <div className="w-50 d-flex justify-content-between pt-9 pb-9 ps-6 pe-6 overlay-border-right">
                <TaskDropduwnStatus statusId={status} statusInfo={statusInfo} update={changeColumn} />
                <div className="d-flex align-items-center overlay-wrap__content-avatar">
                    <ExecutorTask
                        selectUsers={(executors && users.filter(user => executors.includes(user.userId))) || []}
                        users={
                            (executors && projectUsers.filter(user => !executors.includes(user.userId))) || projectUsers
                        }
                        taskId={taskId}
                    />
                </div>
            </div>
            <div className="w-50 pt-9 r-9 ps-6 pe-6 d-flex justify-content-between">
                <div>
                    <p className="font-bold color-grey5 text-small">Создана</p>
                    <p className="mt-1 text-little">{createTime}</p>
                </div>


                <div className="svg-w--20 svg-block">
                    <IconDelete className="ms-2 me-1 cursor-pointer" onClick={onDelete}  />
                </div>
            </div>
        </div>
    )
}

export default TaskInfo
