import { ReactComponent as IconPlus } from '../../assets/menu/plus6.svg'
import { useEffect, useState } from 'react'
import { requests } from '../../requests'

import ProjectAvatarList from '../Components/ProjectAvatarList'

function BlockMenuItem({ isCreate, id, title, projectUsers, teamUsers, click }) {
    const [imgUrl, setImgUrl] = useState('')

    useEffect(() => {
        if (!id) return () => {}

        requests.getProjectFilePreview(id, setImgUrl, () => {
            requests.getRandomImage(setImgUrl)
        })

        return () => {}
    }, [id])

    if (isCreate) {
        return (
            <div className="project-col mt-6">
                <div className="card-project card-project__add flex-center cursor-pointer" onClick={click}>
                    <IconPlus />
                </div>
            </div>
        )
    }

    return (
        <div className="project-col mt-6">
            <div
                className="card-project-image"
                style={{
                    background: '#1E3EFE',
                }}
            ></div>
            {imgUrl && <img className="card-project-image" src={imgUrl} alt="project"/>}
            <div
                className="card-project d-flex flex-column justify-content-between p-5 cursor-pointer"
                style={{ background: imgUrl }}
                onClick={() => click(id)}
            >
                <div className="card-project__top d-flex justify-content-between">
                    <p className="text-great color-white font-bold">{title}</p>
                    {/* <div className="svg-block svg-w--24 cursor-pointer"></div> */}
                </div>
                <div>
                    <ProjectAvatarList projectId={id}/>
                </div>
            </div>
        </div>
    )
}

export default BlockMenuItem
