import { ReactComponent as IconLogo } from '../../assets/logo-pro.svg'

import DropdownTeamInfo from '../Elements/DropdownTeamInfo'
import TarifTeamInfo from '../Elements/TarifTeamInfo'

function TeamInfo({ team, projects }) {
    return (
        <div className="app-sidebar d-flex flex-column flex-shrink p-5 pt-9 h-100">

            {/* <img src="../assets/img/logo-pro.svg" alt="logo"/> */}
            <IconLogo />

            <div className="mt-6">
                {/* {{> app-dropdown/dropdown-team }} */}
                <DropdownTeamInfo id={team.id} title={team.title} users={team.membersInfo}/>
            </div>
            <div className="mt-6">
                {/* {{> app-tariff/tariff-sidebar }} */}
                <TarifTeamInfo 
                    billingPlan={team.activePlanResponse.billingPlan}
                    team={team}
                    projects={projects}
                />
            </div>
        </div>
    )
}

export default TeamInfo