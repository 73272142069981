import store from '../store'
import { setTasks } from '../store/TaskProperties'

import { requests } from '../requests'



export function uploadFileTask(taskId, file) {
    requests.addFileToTask(taskId, file, fileData => {
        const state = store.getState()
        const dispatch = store.dispatch
        const { tasks } = state.tasks
        const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

        setTasksBoard(
            tasks.map(item => {
                if (item.id === taskId) {
                    return {
                        ...item,
                        filesData: [...item.filesData, fileData],
                    }
                }
                return item
            })
        )
    })
}

export function deleteFileTask(taskId, fileId) {
    requests.deleteFileTask(taskId, fileId, () => {
        const state = store.getState()
        const dispatch = store.dispatch
        const { tasks } = state.tasks
        const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

        setTasksBoard(
            tasks.map(item => {
                if (item.id === taskId) {
                    return {
                        ...item,
                        filesData: item.filesData.filter(item => item.id !== fileId)
                    }
                }
                return item
            })
        )
    })
}