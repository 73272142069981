import SettingTariffItem from '../Elements/SettingTariffItem'
import Loading from '../Elements/Loading'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { requests } from '../../requests'
import { useEffect, useState } from 'react'

function TeamSettingTariff() {
    const { teamId } = useParams()
    const teamData = useSelector(state => state.teams.teams.find(item => item.id === Number(teamId)))

    const [tariffPlans, setTariffPlans] = useState([])

    useEffect(() => {
        requests.getTariffPlan(res => {
            setTariffPlans(res)
        })
        return () => {}
    }, [])

    const chechDisable = tariffName => {
        const { billingPlan } = teamData.activePlanResponse

        if (tariffName === 'Free') return true

        const index = tariffPlans.findIndex(tariff => {
            if (tariff.name === billingPlan.name) return true

            return false
        })

        const indexViewTariff = tariffPlans.findIndex(tariff => {
            if (tariff.name === tariffName) return true

            return false
        })

        return indexViewTariff < index
    }

    if (!teamData) return <Loading />

    return (
        <div className="page-cabinet__content js--cabinet-content active">
            <div className="p-9 ps-7 pe-7 d-flex align-items-center justify-content-between">
                <p className="text-big font-medium">Тарифы</p>
            </div>
            <div className="app-border10 w-100"></div>
            <div className="p-7 w-100 d-flex justify-content-between card-upgrades">
                {tariffPlans.map(tariff => (
                    <SettingTariffItem
                        key={tariff.name}
                        tarifInfo={tariff}
                        teamData={teamData}
                        disable={chechDisable(tariff.name)}
                        active={tariff.name === teamData?.activePlanResponse.billingPlan.name}
                    />
                ))}
            </div>
        </div>
    )
}

export default TeamSettingTariff
