import { useState, useRef } from 'react'
import { ReactComponent as IconSend } from '../../assets/task/send.svg'

function NewComment({ create }) {
    const [text, setText] = useState('')
    const ref = useRef()
    return (
        <div className="w-50 pt-9 pb-9 ps-6 pe-6 d-flex align-items-center justify-content-between">
            {/* {{> app-dropdown/dropdown-avatar2 }} */}
            <input
                ref={ref}
                className="w-100 ms-2 me-2 app-textarea d-flex align-items-center pt-1"
                placeholder="Оставьте комментарий"
                value={text}
                onChange={e => setText(e.target.value)}
                onKeyDown={e => {
                    if (e.keyCode === 13) {
                        create(text)
                        setText('')
                        ref.current.blur();
                    }
                }}
            />
            <IconSend
                onClick={() => {
                    create(text)
                    setText('')
                    ref.current.blur();
                }}
            />
        </div>
    )
}

export default NewComment
