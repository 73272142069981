import { setToken } from './fetch'
import { HOST } from './lib'
import { token } from './fetch'
import { fetchWithAuthentication, _arrayBufferToBase64, imagetoblob } from '../pipeline/helpers'

export function usersRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }) {
    return {
        getClientInfo: (on = LOG, off = errorNotification) => {
            const path = PATHS.auth + '/user'

            const params = {
                ...PARAMS,
                method: 'GET',
            }

            sendRequest(path, params, on, off)
        },
        changeClientInfo: (clientInfo, on = LOG, off = errorNotification) => {
            const path = PATHS.auth + '/profile'

            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify(clientInfo),
            }

            sendRequest(
                path,
                params,
                res => {
                    if (res.token) {
                        setToken(res.token)
                    }

                    on(res)
                },
                off
            )
        },
        addNewUserToTeam: (teamId, email, on = LOG, off = errorNotification) => {
            const path = PATHS.team + '/' + teamId + '/' + email + '/invite'

            const params = {
                ...PARAMS,
                method: 'POST',
            }

            sendRequest(path, params, on, off)
        },
        addUserToTeam: (teamId, userId, on = LOG, off = errorNotification) => {
            const path = PATHS.team + '/' + teamId + '/' + userId + '/addMember'

            const params = {
                ...PARAMS,
                method: 'POST',
            }

            sendRequest(path, params, on, off)
        },
        addNewUserToProject: (projectId, email, on = LOG, off = errorNotification) => {
            const path = PATHS.projects + '/' + projectId + '/addUserByEmail?userEmail=' + email

            const params = {
                ...PARAMS,
                method: 'PUT',
                //body: JSON.stringify({ userEmail: email }),
            }

            sendRequest(path, params, on, off)
        },
        addUserToProject: (projectId, userId, on = LOG, off = errorNotification) => {
            const path = PATHS.projects + '/' + projectId + '/addUser?userId=' + userId

            const params = {
                ...PARAMS,
                method: 'PUT',
            }
            
            sendRequest(path, params, on, off)
        },
        deletUsersOnTeam: (teamId, userId, on = LOG, off = errorNotification) => {
            const path = PATHS.team + '/' + teamId + '/' + userId + '/removeMember'

            const params = {
                ...PARAMS,
                method: 'DELETE',
            }

            sendRequest(path, params, on, off, true)
        },
        deletUsersOnProject: (projectId, userId, on = LOG, off = errorNotification) => {
            const path = PATHS.projects + '/' + projectId + '/excludeUser?userId=' + userId

            const params = {
                ...PARAMS,
                method: 'PUT',
            }

            sendRequest(path, params, on, off, true)
        },
        getClientAvatar: (on = LOG, off = LOG) => {
            const path = '/auth/avatar'

            const params = {
                ...PARAMS,
                method: 'GET',
            }

            sendRequest(path, params, on, off)
        },
        getUserAvatar: (userId, on = LOG, off = errorNotification) => {
            const path = `${PATHS.projects}/userLogo?userId=${userId}`

            fetchWithAuthentication(`${HOST}${path}`, token).then(response => {
                if (!response.ok) {
                    off(response)
                    return
                }

                response.arrayBuffer().then(data => {
                    const base64 = _arrayBufferToBase64(data)
                    const dataUrl = `data:image/png;base64,${base64}`
                    on(dataUrl)
                })
            })
        },
        changeClientAvatar: (fileData, on = LOG, off = errorNotification) => {
            const path = '/auth/avatar'

            const img = new FormData()
            img.append('file', imagetoblob(fileData.data), fileData.name)
    
            const params = {
                headers: {
                    Authorization: PARAMS['headers']['Authorization'],
                },
                method: 'POST',
                body: img,
            }
    
            sendRequest(path, params, on, off)
        },
        changePassword: (newPass, on = LOG, off = errorNotification) => {
            const path = PATHS.changePass
 
            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify({ password: newPass }),
            }

            sendRequest(path, params, on, off, true)
        },
        acceptInvitation: (invitationToken, teamId, projectId = 0, on = LOG, off = errorNotification) => {
            const path = `/team/${invitationToken}/${teamId}/${projectId}/acceptInvite`

            const params = {
                ...PARAMS,
                method: 'GET',
            }

            sendRequest(path, params, on, off, true)
        },
        addExecutorTask: (taskId, userId, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + `/${taskId}/addExecutor?executorId=${userId}`

            const params = {
                ...PARAMS,
                method: 'PUT',
            }

            sendRequest(path, params, on, off)
        }
    }
}
