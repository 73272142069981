function TeamSupport() {
    return (
        <div className="page-cabinet__content js--cabinet-content active">
            <div className="p-9 ps-7 pe-7">
                <p className="text-big font-medium">Центр поддержки</p>
            </div>
            <div className="app-border10 w-100"></div>
            <div className="p-7">
                <p className="text-center font-medium text-great">
                    Предлагайте идеи, как сделать Fractal еще более функциональным и удобным.
                </p>
                <p className="text-center font-medium text-great mt-4">Напишите, каких возможностей вам не хватает.</p>

                <div className="position-relative js--teaxtarea">
                    <textarea
                        className="page-cabinet__textarea p-7 w-100 mt-7"
                        maxlength="4000"
                        placeholder="Подробное описание идеи"
                    ></textarea>
                    <div className="page-cabinet__textarea-symbol text-medium">
                        <span className="js--textarea-symbol">0</span>/<span className="js--textarea-symbol-max">4000</span>
                    </div>
                    <button className="btn btn-primary page-cabinet__textarea-btn">Отправить</button>
                </div>
            </div>
        </div>
    )
}

export default TeamSupport
