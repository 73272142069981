import { ReactComponent as IconSettings } from '../../assets/menu/settings.svg'

import { useState, useEffect, useRef } from 'react'
import { useCheckKeyPressed } from '../CustomHooks/CheckKeyPressed.ts'
import { LogoProject } from './Logo'

export function MenuItem({ img, title, isActive, click, openSetting }) {
    return (
        <div
            className={
                'app-menu-open__item d-flex justify-content-between align-items-center ps-5 pe-5 cursor-pointer ' +
                (isActive ? 'active' : '')
            }
            onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                click()
            }}
        >
            <div className="d-flex align-items-center">
                {img && <LogoProject img={img} />}
                <p>{title}</p>
            </div>
            <div
                className="cursor-pointer"
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    openSetting()
                }}
            >
                <IconSettings />
            </div>
        </div>
    )
}

export function TextAndInput({
    text,
    preholder,
    addClass = '',
    update = () => {},
    onSaveTimer = false,
    isCheckEnter = true,
    isEditingSelection = true,
}) {
    function checkPlaceholder(str) {
        if (!str) return true

        if (str.replaceAll(' ', '') === '\n') return true

        if (str.replaceAll(' ', '') === '') return true

        return false
    }

    const [descriptionVal, setDescription] = useState(checkPlaceholder(text) ? preholder : text)
    const [isChange, setIsChange] = useState(false)
    const ref = useRef()

    const checkEnter = isCheckEnter && isChange

    useCheckKeyPressed(
        13,
        () => {
            update(ref.current.innerText)

            ref.current.blur()
            setIsChange(false)
        },
        checkEnter,
        [ref]
    )

    useEffect(() => {
        setDescription(checkPlaceholder(text) ? preholder : text)

        return () => {}
    }, [text, preholder])

    //const { save, clearTimer } = saveTaskAterTimer(update)

    const classStyle = addClass + (isChange && isEditingSelection? ' border-blue' : '')
    return (
        <>
            {
                <p
                    ref={ref}
                    className={classStyle}
                    onBlur={e => {
                        setIsChange(false)
                        //clearTimer()
                        update(e.currentTarget.innerText)

                        if (checkPlaceholder(e.currentTarget.innerText)) {
                            setDescription(preholder)
                        }
                    }}
                    onFocus={e => {
                        setIsChange(true)
                        if (e.currentTarget.innerText === preholder) {
                            setDescription('')
                        }
                    }}
                    onInput={e => {
                        console.log(e.currentTarget.innerText)
                    }}
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                >
                    {descriptionVal}
                </p>
            }
        </>
    )
}

export function IconDatePicker() {
    return (
        <div className="app-tooltip app-tooltip__data position-relative d-flex flex-column align-items-center">
            <div className="app-tooltip__btn flex-center cursor-pointer svg-block w-100 h-100">
                {/* {{> _images/data2 }} */}
            </div>
            <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                <p>Сроки</p>
            </div>
        </div>
    )
}

export function TaskNameProject({ teamName, projectName }) {
    return (
        <div className="d-flex align-items-center">
            {teamName && <p className="color-grizzle">{teamName}</p>}
            {teamName && projectName && <span className="color-grizzle ms-1 me-1">/</span>}
            {projectName && <p>{projectName}</p>}
        </div>
    )
}
