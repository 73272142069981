export function dragEnd({ dInd, index, droppableId, draggableId, oldIndex, arrTasks }) {
    const newState = arrTasks.map(item => {

        if (dInd === droppableId) {
            if(item.statusId !== dInd) return item

            if (item.id === Number(draggableId)) {
                return {
                    ...item, 
                    positionInOrder: index
                }
            }

            if (item.positionInOrder <= index && index - oldIndex > 0 && item.positionInOrder >= oldIndex && item.positionInOrder <= index) {// вниз
                return {
                    ...item, 
                    positionInOrder: item.positionInOrder - 1
                }
            }

            if (item.positionInOrder >= index && index - oldIndex < 0 && item.positionInOrder <= oldIndex && item.positionInOrder >= index) {// вверх
                return {
                    ...item, 
                    positionInOrder: item.positionInOrder + 1
                }
            }

            return item
        }

            if (item.id === Number(draggableId)) {
                return {
                    ...item,
                    statusId: Number(dInd),
                    positionInOrder: index
                }
            }

            if (item.statusId === dInd && item.positionInOrder >= index) {
                return {
                    ...item, 
                    positionInOrder: item.positionInOrder + 1
                }
            }

            if (item.statusId === droppableId && item.positionInOrder > oldIndex) {
                return {
                    ...item, 
                    positionInOrder: item.positionInOrder - 1
                }
            }

            return item
        })

    return newState
}