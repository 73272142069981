import store from '../store'
import { requests } from '../requests'
import { addProject, addTeams } from '../store/Teams'
import { changeViewNotification } from '../store/Notification'

export const createProject = ({ data, teamId, on }) => {
    const dispatch = store.dispatch
    
    const project = {
        title: data.name,
    }

    requests.createProject(teamId, project, res => {
        dispatch(addProject(res))
        on && on(res)
    })
}

export const createTeam = ({ data, on }) => {
    const dispatch = store.dispatch

    requests.createTeam(data, res => {
        on && on(res)
        dispatch(addTeams(res))
    })
}

export function createTimerNotification(oldTimer) {
    oldTimer && clearTimeout(oldTimer)

    return setTimeout(() => {
        const view = { isView: false, type: 'success', text: '' }
        store.dispatch(changeViewNotification(view))
    }, 8000)
}