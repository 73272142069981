export function subtaskRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }) {
    return {
        createSubtask: (projectId, taskId, taskData, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + '/' + projectId + '/' + taskId + '/subAssignment'

            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify({
                    ...taskData,
                }),
            }

            sendRequest(path, params, on, off)
        },
        changeSubtask: (projectId, subtaskId, taskData, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + '/' + projectId + '/' + subtaskId + '/subAssignment'

            const params = {
                ...PARAMS,
                method: 'PUT',
                body: JSON.stringify({
                    ...taskData,
                }),
            }

            sendRequest(path, params, on, off)
        },
        deleteSubtask: (projectId, subtaskId, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + '/' + projectId + '/' + subtaskId + '/subAssignment'

            const params = {
                ...PARAMS,
                method: 'DELETE',
            }

            sendRequest(path, params, on, off)
        },
    }
}
