import AvatarList from '../Elements/AvatarList'
import ProjectUserItem from '../Elements/ProjectUserItem'
import { useSelector } from 'react-redux'
import { useState, useRef } from 'react'
import { useStateVisibleComponent } from '../CustomHooks/ClickOutside'

import { searchInStrings } from '../../pipeline/helpers'
import { addUserOnProject, deleteUserOnProject } from '../../pipeline/BasicPipelines'

function ProjectAvatarList({ projectId }) {
    const project = useSelector(state => state.teams.projects.find(pr => pr.id === Number(projectId)))
    const team = useSelector(state => state.teams.teams.find(te => te.id === project?.teamId))
    const clientInfo = useSelector(state => state.users.clientInfo)

    const ref = useRef()
    const [text, setText] = useState('')
    const [isView, setView] = useStateVisibleComponent(ref)

    if (!project || !team) return <></>

    const addUsers = team.membersInfo.filter(user => !project.userInfos.find(us => us.userId === user.userId))
    const addClass = 'app-dropdown__overlay js--dropdown-content p-9' + (isView ? ' open' : '')

    return (
        <div
            className="app-dropdown app-dropdown__user-add cursor-pointer position-relative svg-block js--dropdown"
            ref={ref}
            onClick={e => {
                e.stopPropagation()
            }}
        >
            <div className="cursor-pointer js--dropdown-open">
                <AvatarList
                    users={project.userInfos}
                    onClick={e => {
                        e.stopPropagation()
                        setView(!isView)
                    }}
                />
            </div>

            <div className={addClass}>
                <div className="input-search d-flex align-items-center svg-block w-100 p-2 ps-4 pe-4">
                    <div className="cursor-pointer">{/* {{> _images/search }} */}</div>
                    <input
                        type="text"
                        placeholder="Поиск участника"
                        className="w-100 h-100 ms-2"
                        value={text}
                        onChange={e => setText(e.target.value)}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    />
                </div>
                <div className="mt-6">
                    <p className="text-medium">Назначены на проект</p>
                    <div className="mt-1">
                        {searchInStrings(text, project.userInfos, 'name').map(({ userId, name }) => (
                            <ProjectUserItem
                                key={userId}
                                userId={userId}
                                name={name}
                                typeButton={project.moderatorId !== clientInfo.userId && 'delete'}
                                onClick={() => deleteUserOnProject({ userId }, project.id)}
                            />
                        ))}
                    </div>
                </div>
                {addUsers[0] && (
                    <div className="mt-6">
                        <p className="text-medium">Не назначены на проект</p>
                        <div className="mt-1">
                            {searchInStrings(text, addUsers, 'name').map(user => (
                                <ProjectUserItem
                                    key={user.userId}
                                    userId={user.userId}
                                    name={user.name}
                                    onClick={() => addUserOnProject(user, project.id)}
                                    typeButton={'create'}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProjectAvatarList
