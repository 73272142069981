import { imagetoblob, fetchWithAuthentication, _arrayBufferToBase64 } from '../pipeline/helpers'
import { HOST } from './lib'
import { token } from './fetch'

export function fileSystemRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }) {
    return {
        addFileToTask: (taskId, fileData, on = LOG, off = errorNotification) => {
            const img = new FormData()
            img.append('file', imagetoblob(fileData.data), fileData.name)

            const path = `${PATHS.tasks}/${taskId}/file`

            const params = {
                headers: {
                    Authorization: PARAMS['headers']['Authorization'],
                },
                method: 'POST',
                body: img,
            }

            sendRequest(path, params, on, off)
        },
        getImageFilePreview: (taskId, fileId, on = LOG, off = errorNotification) => {
            const path = `${PATHS.tasks}/${taskId}/previewFile/${fileId}`

            fetchWithAuthentication(`${HOST}${path}`, token).then(response => {
                if (!response.ok) {
                    off(response)
                    return
                }

                response.arrayBuffer().then(data => {
                    const base64 = _arrayBufferToBase64(data)
                    const dataUrl = `data:image/png;base64,${base64}`
                    on(dataUrl)
                })
            })
        },
        downloadFile: (taskId, fileId, fileName) => {
            if (!fileName || fileName === '') {
                console.log('sorry, file not found')
                return
            }

            const url = `${HOST}${PATHS.tasks}/${taskId}/file/${fileId}`

            const xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.setRequestHeader('Authorization', PARAMS['headers']['Authorization'])
            xhr.responseType = 'blob'
            xhr.onload = function (e) {
                const urlCreator = window.URL || window.webkitURL
                const imageUrl = urlCreator.createObjectURL(this.response)
                const tag = document.createElement('a')
                tag.href = imageUrl
                tag.download = fileName
                document.body.appendChild(tag)
                tag.click()
                document.body.removeChild(tag)
            }
            xhr.send()
        },
        deleteFileTask: (taskId, fileId, on = LOG, off = errorNotification) => {
            const path = `${PATHS.tasks}/${taskId}/file/${fileId}`

            const params = {
                ...PARAMS,
                method: 'DELETE',
            }

            sendRequest(path, params, on, off, true)
        },
        getProjectFilePreview: (projectId, on = LOG, off = errorNotification) => {
            const path = `${PATHS.projects}/${projectId}/logoPreview`

            fetchWithAuthentication(`${HOST}${path}`, token).then(response => {
                if (!response.ok) {
                    off(response)
                    return
                }

                response.arrayBuffer().then(data => {
                    const base64 = _arrayBufferToBase64(data)
                    const dataUrl = `data:image/png;base64,${base64}`
                    on(dataUrl)
                })
            })
        },
        changeLogoProject: (projectId, file, on = LOG, off = errorNotification) => {
            const path = `${PATHS.projects}/${projectId}/logo`

            const img = new FormData()
            img.append('file', imagetoblob(file.data), file.name)

            const params = {
                headers: {
                    Authorization: PARAMS['headers']['Authorization'],
                },
                method: 'POST',
                body: img,
            }

            sendRequest(path, params, on, off)
        },
        getTeamFilePreview: (teamId, on = LOG, off = errorNotification) => {
            // const path = `${PATHS.projects}/${projectId}/logoPreview`
            // fetchWithAuthentication(`${HOST}${path}`, token).then(response => {
            //     if (!response.ok) {
            //         off(response)
            //         return
            //     }
            //     response.arrayBuffer().then(data => {
            //         const base64 = _arrayBufferToBase64(data)
            //         const dataUrl = `data:image/png;base64,${base64}`
            //         on(dataUrl)
            //     })
            // })
        },
        getRandomImage: (on = LOG, off = errorNotification) => {
            const category = 'nature'
            const path = 'https://api.api-ninjas.com/v1/randomimage?category=' + category
            const api_key = 'IZMCVof1lpJklGwJnH2thg==gY3qzogAzIV3ovHs'

            const params = {
                headers: {
                    ...PARAMS.headers,
                    'X-Api-Key': api_key,
                    Accept: 'image/jpg',
                },
            }

            fetch(path, params)
                .then(response => {
                    return response.blob()
                })
                .then(imageBlob => {
                    const imageObjectURL = URL.createObjectURL(imageBlob)

                    on(imageObjectURL)
                })
                .catch(off)
        },
    }
}
