import { TextAndInput } from './MiniComponents'

function CardSubtask({ title, checkbox, id, update }) {
    return (
        <div className="app-card__subtasks ps-4 pe-4">
            <div className="app-border2"></div>
            <div className="d-flex justify-content-between align-items-center pt-2 mb-2">
                <div
                    className="app-checkbox cursor-pointer"
                    onClick={() =>
                        update({
                            id,
                            checked: !checkbox,
                        })
                    }
                >
                    <input className="app-checkbox__input cursor-pointer" type="checkbox" checked={checkbox} />
                    <label className="font-medium cursor-pointer"></label>
                </div>

                <div className="font-medium w-100">
                    <TextAndInput
                        text={title}
                        preholder={'Введите название подзадачи'}
                        update={data =>
                            update({
                                id,
                                title: data,
                            })
                        }
                        isCheckEnter={true}
                        addClass="w-100"
                    />
                </div>
                {/* <div className="app-card__avatar flex-center bg-violet font-medium text-medium m-1">S</div> */}
            </div>
        </div>
    )
}

export default CardSubtask
