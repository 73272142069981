import { useState, useEffect } from "react"

function KanbanAddTask({ setOpen, create }) {

    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Enter') {
            event.preventDefault();

            create(inputValue)
            setInputValue('')
            setOpen(false)
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, [ inputValue, setOpen, create ]);
    
    return (
        <div className="app-boards__input js--board-search-input  overflow-hidden">
            <input type="text" placeholder="Введите название задачи" className="w-100 p-4 mt-4" value={inputValue} 
                onChange={e => 
                    setInputValue(e.target.value)}/>
        </div>
    )
}

export default KanbanAddTask