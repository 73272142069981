import store from '../store'
import { changeViewNotification } from '../store/Notification'


export const errorNotification = (err) => {
    if (!err || typeof err !== 'string') return

    if (err === `TokenNotMatchException: Token doesn't match`) {
        console.log('TOKEN ERROR');
        window.location.href = '/#/login'

        return
    }

    const dispatch = store.dispatch

    const notificationError = {
        isView: true,
        type: 'error',
        text: err,
    }

    dispatch(changeViewNotification(notificationError))
}

export const addNewUserToTeamNotification = () => {
    const dispatch = store.dispatch

    const notificationSuccess = {
        isView: true,
        type: 'success',
        text: 'На почту было выслано приглашение',
    }

    dispatch(changeViewNotification(notificationSuccess))
}

export function notificationCompletePayment() {
    const dispatch = store.dispatch

    const notificationSuccess = {
        isView: true,
        type: 'success',
        text: 'Оплата прошла успешно',
    }

    dispatch(changeViewNotification(notificationSuccess))
}

export function notificationErrorPayment() {
    const dispatch = store.dispatch

    const notificationError = {
        isView: true,
        type: 'error',
        text: 'Что-то пошло не так, попробуйте еще раз',
    }

    dispatch(changeViewNotification(notificationError))
}