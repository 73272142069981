

function InputPassword({ password, onChange, addClass = '' }) {

    return (
        <div className='d-flex flex-row'>
            <input
                className={addClass}
                value={password}
                onChange={e => onChange(e.target.value)}
                type={false ? 'text' : 'password'}
                id="password-input"
                placeholder="Введите пароль"
            />
        </div>
    )
}

export default InputPassword
