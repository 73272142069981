import { createPortal } from 'react-dom'
import { useState, useEffect } from 'react'
import { requests } from '../../requests'
import { initPurchase, conversionCurrency } from '../../pipeline/PaymentPipeline'

import { ReactComponent as IconClose } from '../../assets/menu/close4.svg'

import SelectList from '../Elements/SelectList'

import { errorNotification } from '../../pipeline/notificationPipeline'

const preState = {
    basicPrice: 0,
    discount: 0,
    isWrong: false, // что-то пошло не так
    totalPrice: 0,
    userCountIsWrong: false,
}

function ChoiceTariffPopup({ isView, setView, tarifInfo, teamData, defaultState }) {
    const { name, cost } = tarifInfo
    const { usersCount } = teamData?.activePlanResponse

    const [selectData, setSelectData] = useState({
        preset: name?.toUpperCase(),
        monthCount: defaultState?.monthCount || 1,
        usersCount: defaultState?.usersCount || usersCount || 1,
    })

    const [priceInfo, setPriceInfo] = useState(preState)

    useEffect(() => {
        requests.calculationTariffPlan(teamData.id, selectData, res => {
            if (isView && res.isWrong) errorNotification(res.errorMessage)
            setPriceInfo(res)
        })

        return () => {}
    }, [selectData, teamData, isView])

    return createPortal(
        <>
            {isView && (
                <div className="overlay overlay-tariff d-flex align-items-center justify-content-center w-100 h-100 overlay-show">
                    <div className="overlay-wrap overlay-scroll position-relative">
                        <div className="d-flex justify-content-end w-100">
                            <div className="overlay-close cursor-pointer flex-center js--overlay-close">
                                <IconClose onClick={() => setView(false)} />
                            </div>
                        </div>
                        <div className="overlay-wrapper  d-flex flex-column">
                            <div className="p-6">
                                <p className="text-large font-medium">
                                    Подключение плана <span className="color-blue text-large font-medium">{name}</span>{' '}
                                    для&nbsp;
                                    {teamData?.title}
                                </p>
                            </div>
                            <div className="app-border10 w-100"></div>
                            <div className="p-6 d-flex justify-content-between">
                                <div className="me-4">
                                    <p className="font-medium">Количество пользователей</p>
                                    <div className="mt-4">
                                        {/* {{> app-select/select-party }} */}
                                        <SelectList
                                            type={'users'}
                                            value={selectData.usersCount}
                                            onChange={value =>
                                                setSelectData(state => {
                                                    return {
                                                        ...state,
                                                        usersCount: value,
                                                    }
                                                })
                                            }
                                            minValue={teamData?.activePlanResponse.usersCount}
                                        />
                                    </div>
                                    <p className="font-medium mt-6">Количество месяцев</p>
                                    <div className="mt-4">
                                        <SelectList
                                            type={'months'}
                                            value={selectData.monthCount}
                                            onChange={value =>
                                                setSelectData(state => {
                                                    return {
                                                        ...state,
                                                        monthCount: value,
                                                    }
                                                })
                                            }
                                        />
                                        {/* {{> app-select/select-month }} */}
                                    </div>
                                </div>
                                <div className="tariff-result w-100 ms-4 d-flex flex-column justify-content-between">
                                    <div className="p-4 w-100">
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <p>Итого</p>
                                            <p className="font-medium">
                                                {priceInfo.totalPrice && conversionCurrency(priceInfo.totalPrice)} ₽{' '}
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center w-100 mt-4">
                                            <p className="font-medium color-grey">За пользователя</p>
                                            <p className="font-medium color-grey">
                                                {cost && conversionCurrency(cost)} ₽{' '}
                                            </p>
                                        </div>

                                        <button
                                            className="btn btn-primary w-100 mt-6"
                                            onClick={() =>
                                                initPurchase({
                                                    purchaseData: selectData,
                                                    teamId: teamData.id,
                                                })
                                            }
                                        >
                                            Подключить
                                        </button>
                                    </div>
                                    {priceInfo && priceInfo.discount !== 0 && (
                                        <p className="tariff-result--economy w-100 text-little">
                                            Вы экономите {conversionCurrency(priceInfo.discount)} ₽
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>,
        document.body
    )
}

export default ChoiceTariffPopup
