import { ReactComponent as IconPlus } from '../../assets/kanban/plus.svg'
import { ReactComponent as IconTooltipTag } from '../../assets/kanban/tooltip.svg'

import { useStateVisibleComponent } from '../CustomHooks/ClickOutside'
import { useCheckKeyPressed } from '../CustomHooks/CheckKeyPressed.ts'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { calculateCoordinateHTMLElement } from '../../pipeline/helpers'

function TagsCard({ tag, allTags, update }) {
    const ref = useRef(null)
    const refOverlay = useRef(null)
    const [isOpenAddTags, setIsOpenAddTags] = useStateVisibleComponent(refOverlay)
    const [text, setText] = useState('')

    useCheckKeyPressed(
        13,
        () => {
            update({
                newTag: {
                    name: text,
                },
            })

            setIsOpenAddTags(!isOpenAddTags)
            setText('')
        },
        isOpenAddTags
    )

    const { x, y } = calculateCoordinateHTMLElement(ref)

    return (
        <div className="app-dropdown app-dropdown__mark position-relative svg-block js--dropdown" ref={ref}>
            {tag && (
                <div
                    className="app-dropdown__open js--dropdown-open cursor-pointer mt-2"
                    onClick={e => {
                        e.stopPropagation()
                        setIsOpenAddTags(!isOpenAddTags)
                    }}
                >
                    <Tag title={tag.name} color={tag.color} backColor={tag.color + '77'} />
                </div>
            )}

            {!tag && (
                <div className="app-tooltip position-relative w-100 h-100 d-flex flex-column align-items-center app-dropdown__tooltip">
                    <div
                        className="app-tooltip__btn flex-center cursor-pointer svg-block w-100 h-100 "
                        onClick={e => {
                            e.stopPropagation()
                            setIsOpenAddTags(!isOpenAddTags)
                        }}
                    >
                        <IconTooltipTag />
                    </div>
                    <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                        <p>Метки</p>
                    </div>
                </div>
            )}

            {createPortal(
                <>
                    {isOpenAddTags && (
                        <div
                            className="app-dropdown__overlay js--dropdown-content js--dropdown-content-close pt-3 pb-3 ps-4 pe-4 open"
                            ref={refOverlay}
                            style={{
                                top: y + 'px',
                                left: x + 'px',
                                zIndex: 5000,
                            }}
                        >
                            <p className="p-0 mb-2">Метки</p>
                            <div className="d-flex flex-wrap svg-block">
                                {allTags &&
                                    allTags[0] &&
                                    allTags.map(item => (
                                        <div
                                            key={item.id}
                                            className="me-1 mb-2"
                                            onClick={e => {
                                                e.stopPropagation()
                                                update({ addTag: item })
                                                setIsOpenAddTags(!isOpenAddTags)
                                            }}
                                        >
                                            <Tag title={item.name} color={item.color} backColor={item.color + '77'} />
                                        </div>
                                    ))}
                            </div>
                            <div
                                className="d-flex align-items-center mt-2 pt-10 pb-10 cursor-pointer"
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                            >
                                <div className="overlay-status__input me-2">
                                    <input
                                        type="text"
                                        placeholder="Название статуса"
                                        className="w-100"
                                        value={text}
                                        onChange={e => setText(e.target.value)}
                                        autoFocus
                                    />
                                </div>
                                <IconPlus
                                    onClick={e => {
                                        e.stopPropagation()
                                        update({
                                            newTag: {
                                                name: text,
                                            },
                                        })
                                        setIsOpenAddTags(!isOpenAddTags)
                                        setText('')
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </>,
                document.body
            )}
        </div>
    )
}

export function Tag({ title, backColor, color }) {
    return (
        <div className="ms-2">
            <p className="app-mark text-little cursor-pointer" style={{ background: backColor, color: color }}>
                {title}
            </p>
        </div>
    )
}

export default TagsCard
