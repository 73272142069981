import KanbanCard from './KanbanCard'
import KanbanAddTask from '../Elements/KanbanAddTask'
import TitleColumnKanban from './TitleColumnKanban'
import KanbanAddTaskOnEndColumn from '../Elements/KanbanAddTaskOnEndColumn'
import { useState } from 'react'

import { Droppable, Draggable } from 'react-beautiful-dnd'

function KanbanColumn({ status, tasks, tags, createNewTask, updateCard, projectUsers }) {
    const [isOpenAddTask, setOpenAddTask] = useState(false)
    const [isHover, setShowHovere] = useState(false)

    return (
        <div
            className="app-boards app-boards--work w-100 h-100 d-flex js--board-search"
            onMouseEnter={() => {
                setShowHovere(true)
            }}
            onMouseLeave={() => {
                setShowHovere(false)
            }}
        >
            <div className="app-boards__column w-100 h-100 ps-4 pe-4 js--board pb-7 scroll-y overflow-hidden-x">
                <TitleColumnKanban
                    setOpenAddTask={setOpenAddTask}
                    isOpenAddTask={isOpenAddTask}
                    title={status.name}
                    color={status.color}
                    id={status.statusId}
                />

                {isOpenAddTask && (
                    <KanbanAddTask setOpen={setOpenAddTask} create={title => createNewTask(status.statusId, title)} />
                )}

                <Droppable droppableId={`${status.statusId}`}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            //style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                            className="app-boards__cards js--board-hide h-100 scroll-y overflow-hidden-x"
                        >
                            {tasks.map((task, index) => (
                                <Draggable key={task.id} draggableId={`${task.id}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="pt-4" key={task.id}>
                                                <KanbanCard
                                                    card={task}
                                                    updateCard={updateCard}
                                                    index={index}
                                                    allTags={tags}
                                                    projectUsers={projectUsers}
                                                    columnInfo={status}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            <KanbanAddTaskOnEndColumn
                                isHover={isHover}
                                createTask={title => createNewTask(status.statusId, title)}
                            />
                        </div>
                    )}
                </Droppable>

                {/* <KanbanAddTaskOnEndColumn isHover={isHover} createTask={createNewTask} /> */}
            </div>
        </div>
    )
}

export default KanbanColumn
