import { requests } from "../requests";
import { HOST } from "../requests/lib";

export function initPurchase({ purchaseData, teamId }) {
    requests.generateInvoice(teamId, purchaseData, res => initPayment({ paymentData: res?.PaymentCommand, teamId }))
}

function initPayment({ paymentData, teamId }) {
    const isDev = HOST.indexOf('dev')
    const hostFront = isDev === -1?  'https://app.fractals.work/' : 'https://app.dev.fractals.work/'
    const completeUrl = `${hostFront}#/team/${teamId}/profile?paymentStatus=success`   
    const failedUrl = `${hostFront}#/team/${teamId}/profile?paymentStatus=failed`
   
    const reqData = {
        ...paymentData,
        SuccessURL: completeUrl,
        FailURL: failedUrl,
    }

    requests.initPayment(reqData, res => {
        if (!res.PaymentURL) return

        window.location.replace(res.PaymentURL)
    })
}

export function conversionCurrency(cost, subUnits = 2) {
    if (!cost) return 0

    if (typeof cost !== 'number') return 0

    return cost / Math.pow(10, subUnits)
}