export function paymentRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }) {
    return {
        getTaskComments: (taskId, on = LOG, off = errorNotification) => {
            const path = PATHS.tasks + '/' + taskId + '/comments'

            const params = {
                ...PARAMS,
                method: 'GET',
            }

            sendRequest(path, params, on, off)
        },
        getTariffPlan: (on = LOG, off = errorNotification) => {
            const path = PATHS.purchase + '/getBillingPlans'
            
            const params = {
                ...PARAMS,
                method: 'GET',
            }

            sendRequest(path, params, on, off)
        },
        calculationTariffPlan: (teamId, data, on = LOG, off = errorNotification) => {
            const path = PATHS.purchase + '/' + teamId + '/calculatePrice'

            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify(data)
            }

            sendRequest(path, params, on, off)
        },
        generateInvoice: (teamId, data, on = LOG, off = errorNotification) => {
            const path = PATHS.purchase + '/' + teamId + '/generateInvoice'

            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify(data)
            }

            sendRequest(path, params, on, off)
        },
        initPayment: (data, on = LOG, off = errorNotification) => {
            const path = PATHS.payment + '/initPayment'

            const params = {
                ...PARAMS,
                method: 'POST',
                body: JSON.stringify(data)
            }

            sendRequest(path, params, on, off)
        }
    }
}
