import CommentTask from '../Elements/CommentTask'
import { useSelector } from 'react-redux'
import { useRef } from 'react'

function CommentsTask({ comments }) {
    const ref = useRef()
    const users = useSelector(state => state.users.users)

    setTimeout(() => addNewContent(ref))
    return (
        <div className="w-50 ps-6 pe-6 pt-5 pb-6 overlay-scroll bg-light2" ref={ref}>
            {comments.map(({ content, commentId, creationTime, userId, isUtility }) => (
                <CommentTask
                    key={commentId}
                    content={content}
                    creationTime={creationTime}
                    user={users.find(item => item.userId === userId)}
                    isUtility={isUtility}
                />
            ))}
        </div>
    )
}

export default CommentsTask

function addNewContent(ref) {
    if (ref && ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight
    }
}
