import { TextAndInput } from '../Elements/MiniComponents'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { changeTeams } from '../../store/Teams'
import { requests } from '../../requests'
import { useEffect, useState } from 'react'
import { notificationCompletePayment, notificationErrorPayment } from '../../pipeline/notificationPipeline'
import { daysUntilDate, dayTitle } from '../../pipeline/helpers'
import Loading from '../Elements/Loading'

import ChoiceTariffPopup from './ChoiceTariffPopup'

function TeamProfile() {
    const { teamId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const teamData = useSelector(state => state.teams.teams.find(item => item.id === Number(teamId)))
    const projects = useSelector(state => state.teams.projects.filter(item => item.teamId === Number(teamId)))

    const [isVeiwPopup, setVeiwPopup] = useState(false)

    const uptade = data => {
        const newData = {
            ...teamData,
            ...data,
        }

        dispatch(
            changeTeams({
                type: 'team',
                data: newData,
            })
        )

        requests.changeTeam(newData, res =>
            dispatch(
                changeTeams({
                    type: 'team',
                    data: res,
                })
            )
        )
    }

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const paymentStatus = searchParams.get('paymentStatus')

        if (typeof paymentStatus === 'undefined') return () => {}

        if (paymentStatus === 'success') {
            notificationCompletePayment()
        }

        if (paymentStatus === 'failed') {
            notificationErrorPayment()
        }

        searchParams.delete('paymentStatus')
        setSearchParams(searchParams)

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!teamData) return <Loading />

    const endTimeTariff = daysUntilDate(teamData.activePlanResponse.endTime)

    return (
        <div className="page-cabinet__content js--cabinet-content active">
            <div className="p-9 ps-7 pe-7">
                <p className="text-big font-medium">Профиль команды</p>
            </div>
            <div className="app-border10 w-100"></div>
            <div className="p-7 d-flex justify-content-between align-items-start">
                <div className="w-100 maxW-400">
                    <p className="font-medium mb-4">Название команды</p>
                    <div className="input-basic">
                        <TextAndInput
                            text={teamData.title}
                            preholder="Введите название команды"
                            addClass="ps-4 pe-4 w-100 h-100 input-bas"
                            update={text => uptade({ title: text })}
                        />
                    </div>
                    <div className="mt-6 d-flex align-items-center justify-content-between w-100">
                        <p className="font-medium color-grey">Тарифный план</p>
                        <p className="color-blue text-underline cursor-pointer" onClick={() => navigate('../tariff')}>
                            Изменить
                        </p>
                    </div>
                    <p className="mt-2">{teamData.activePlanResponse.billingPlan.name}</p>
                    <div className="app-border10 w-100 mt-5"></div>
                    <p className="font-medium color-grey mt-2">Максимум проектов</p>
                    <p className="mt-2">
                        {projects && projects.length}/{teamData.activePlanResponse.billingPlan.maxProjects}
                    </p>
                    <div className="app-border10 w-100 mt-5"></div>
                    <p className="font-medium color-grey mt-2">Максимум пользователей</p>
                    <p className="mt-2">
                        {teamData.membersInfo.length}/{teamData.activePlanResponse.usersCount}
                    </p>

                    {teamData.activePlanResponse.billingPlan.name !== 'Free' && (
                        <>
                            <div className="app-border10 w-100 mt-5"></div>
                            <p className="font-medium color-grey mt-2">
                                До окончания тарифного плана осталось{' '}
                                <span className="font-bold color-dark">{endTimeTariff}</span> {dayTitle(endTimeTariff)}
                            </p>
                            <button className="btn btn-blue-dark w-100 mt-5" onClick={() => setVeiwPopup(true)}>
                                Продлить
                            </button>
                        </>
                    )}
                    {/* <p className="mt-2">{teamData.membersInfo.length}/{teamData.activePlanResponse.usersCount}</p> */}
                </div>
                {/* <div className="ms-2 me-4 d-flex flex-column">
                    <p className="font-medium mb-4">Аватар команды</p>
                </div> */}
            </div>
            {/* <div className="app-border10 w-100"></div>
            <div className="p-7">
                <p className="color-error2 text-underline">Удалить команду</p>
                <p className="mt-9 text-little">
                    Вы получите электронное письмо для подтверждения вашего решения. Это не приведет к прекращению вашей
                    подписки или платежей, и с вас будет по-прежнему взиматься плата. Вы можете отменить подписку или
                    изменить способ оплаты, чтобы команда оставалась активной.
                </p>
                <p className="text-little">
                    Это не может быть отменено. Все созданные вами доски будут безвозвратно удалены. Вы можете сохранять
                    резервные копии или экспортировать их.
                </p>
            </div> */}
            {teamData.activePlanResponse.billingPlan.name !== 'Free' && <ChoiceTariffPopup
                isView={isVeiwPopup}
                setView={setVeiwPopup}
                tarifInfo={teamData.activePlanResponse.billingPlan}
                teamData={teamData}
                defaultState={{ 
                    monthCount: Math.ceil(endTimeTariff/30 + 1)
                }}
            />}
        </div>
    )
}

export default TeamProfile
