import { useSelector } from "react-redux"

export function useGetProjectAndTeamName(projectId) {
    const project = useSelector(state => state.teams.projects.find(proj => proj.id === Number(projectId)))
    const team = useSelector(state => state.teams.teams.find(proj => proj.id === project?.teamId))

    return {
        projectName: project?.title || '',
        teamName: team?.title || '',
        teamId: team?.id,
    }
}