import { createPortal } from 'react-dom'
import { useState } from 'react'
import { ReactComponent as IconConfirmation } from '../../assets/task/confirmationDeleteTask.svg'
import { ReactComponent as IconCancel } from '../../assets/task/cancelDeleteTask.svg'

function PopapDeleteTask({ isView, onConfirmation, onCancel }) {
    const [isHoverYes, setHoverYes] = useState(false)
    const [isHoverNo, setHoverNo] = useState(false)

    const confirmButtonClassName = `popup-delete-task-button-confirm on-hover ${isHoverYes ? '' : 'opacity-0'}`
    const cancelButtonClassName = `popup-delete-task-button-cancel on-hover-cancel ${isHoverNo ? '' : 'opacity-0'}`
    return createPortal(
        <>
            {isView && 
                <div className="popup-delete-task-background">
                    <div className="popup-delete-task">
                        <div>Вы действительно хотите удалить задачу?</div>
                        <div className="popup-delete-task-buttons">
                            <div className="popup-delete-task-button-confirm">
                                Да
                            </div>
                            <div 
                                className={confirmButtonClassName} 
                                onMouseLeave={() => setHoverYes(false)}
                                onMouseEnter={() => setHoverYes(true)}
                                onClick={() => onConfirmation()}    
                            >
                                <IconConfirmation />
                            </div>
                            <div className="popup-delete-task-button-cancel">
                                Нет
                            </div>
                            <div 
                                className={cancelButtonClassName}
                                onMouseLeave={() => setHoverNo(false)}
                                onMouseEnter={() => setHoverNo(true)}
                                onClick={onCancel}      
                            >
                                <IconCancel />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>, document.body
    )
}

export default PopapDeleteTask
