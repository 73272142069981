import { ReactComponent as IconLowImportance } from '../../assets/kanban/importanceLow.svg'
import { ReactComponent as IconNormalImportance } from '../../assets/kanban/importanceNormal.svg'
import { ReactComponent as IconMiddleImportance } from '../../assets/kanban/importanceMiddle.svg'
import { ReactComponent as IconHightImportance } from '../../assets/kanban/importanceHight.svg'
import { ReactComponent as IconFlag } from '../../assets/kanban/flag.svg'

import { useStateVisibleComponent } from '../CustomHooks/ClickOutside'
import { useRef } from 'react'

function PriorityCard({ priority, update, isTitle = false, isChange = true, typeView = 'data' }) {

    const ref = useRef(null);
    const [isOpenAddTags, setIsOpenAddTags] = useStateVisibleComponent(ref)

    return (
        <div className="app-dropdown app-dropdown__status cursor-pointer position-relative svg-block js--dropdown" 
            ref={ref}>
            {typeView === 'data' && <div className="app-dropdown__open js--dropdown-open d-flex flex-wrap" onClick={e => {
                    if (!isChange) return
                    e.stopPropagation()
                    e.preventDefault()
                    setIsOpenAddTags(!isOpenAddTags)
                }}>
                { typePriority[priority]?.component }
                { isTitle && <p className={"ms-1 d-flex align-items-center " + typePriority[priority].color}> {typePriority[priority].title} </p> }
            </div>}

            {typeView === 'icon' && <div className="app-tooltip position-relative w-100 h-100 d-flex flex-column align-items-center">
                <div className="app-tooltip__btn flex-center cursor-pointer svg-block w-100 h-100">
                    <IconFlag />
                </div>
                <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                    <p>Приоритет</p>
                </div>
            </div>}

            {isOpenAddTags && <div className="app-dropdown__overlay js--dropdown-content js--dropdown-content-close open">
                {Object.keys(typePriority).map(priority => 
                    <Piority type={priority} click={data => {
                        setIsOpenAddTags(false)
                        update(data)
                    }}/>
                )}
            </div>}
        </div>
    )
}

export function Piority({ type, click }) {
    return (
        <div className="pt-10 pb-10 ps-4 pe-4 d-flex align-items-center" onClick={e =>{
            e.stopPropagation()
            e.preventDefault()
            
            click({
            importance: typePriority[type].updateValue,
            importanceScale: `${type}`
        })}}>
            <div className="d-flex flex-shrink">
                { typePriority[type].component }
            </div>
            <p className={"ms-1 " + typePriority[type].color}> {typePriority[type].title} </p>
        </div>
    )
}

const typePriority = {
    LOW: {
        id: 1,
        component: <IconLowImportance />,
        title: 'Низкий',
        color: 'color-grey-text4',
        updateValue: 24,//importance
    },
    NORMAL: {
        id: 2,
        component: <IconNormalImportance />,
        title: 'Нормальный',
        color: 'bg-blue-btn',
        updateValue: 49,
    },
    MEDIUM: {
        id: 3,
        component: <IconMiddleImportance />,
        title: 'Средний',
        color: 'color-orange',
        updateValue: 74,
    },
    HIGH: {
        id: 4,
        component: <IconHightImportance />,
        title: 'Высокий',
        color: 'color-error2',
        updateValue: 99,
    },
}

export default PriorityCard