import { useRef } from "react"
import { readUploadFile } from "../../pipeline/helpers"

function ProjectAvatar({ imgUrl, name, id, on }) {
    const inputFile = useRef()

    const choiseUploadFiles = () => {
        const input = inputFile.current
        const files = input.files

        readUploadFile(id, files, (id, fileData) => {
            on && on(fileData)
        })
    }

    return (
        <div className="app-upload form-upload d-flex align-items-center position-relative cursor-pointer">
            <input
                type="file"
                id="fileselect"
                ref={inputFile}
                multiple={false}
                onChange={choiseUploadFiles}
                className="cursor-pointer w-100 h-100"
            />
            <div className="app-upload__avatar font-bold flex-column" id="filedrag">
                {/* <IconAvatar userId={userInfo.userId} addClass="app-form__upload-avatar flex-center font-medium" /> */}
                {imgUrl && <img className='app-upload__avatar flex-center font-bold' src={imgUrl} alt='project avatar' />}
                {!imgUrl && <div className="app-upload__avatar flex-center font-bold">{ name }</div>}
                <p className="btn btn-grey2 mt-4 p-10 ps-4 pe-4 font-weight h-max"> Загрузить </p>
            </div>
        </div>
    )
}

export default ProjectAvatar
