import { ReactComponent as IconArrow } from '../../assets/task/arrow3.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { requests } from '../../requests'

import ListTaskStatus from '../Elements/ListTaskStatus'
import ListTaskSubtaskIcon from '../Elements/ListTaskSubtaskIcon'
import TagsCard from '../Elements/TagsCard'
import DeadlineCard from '../Elements/DeadlineCard'
import ExecutorTask from './ExecutorTask'
import PriorityCard from '../Elements/PriorityCard'
import ListSubtask from '../Elements/ListSubtask'

function ListTask({ task, allTags, projectUsers, updateCard, statusInfo }) {
    const { projectId } = useParams()
    let navigate = useNavigate()

    const [isSubtaskView, setSubTasksView] = useState(false)
    const users = useSelector(state => state.users.users)

    const update = (change, settings) => {
        const dataChange = {
            id: task.id,
            ...change,
        }

        updateCard(dataChange, settings)
    }

    const updateSubTask = (data) => {
        requests.changeSubtask(projectId, data.id, {
            ...data,
            id: data.id,
        })

        const newTask = {
            ...task,
            subAssignmentsResponse: task.subAssignmentsResponse.map(
                item => {
                    if (item.id === data.id) {
                        return {
                            ...item,
                            ...data,
                        }
                    }

                    return item
                }
            ),
        }

        update(newTask, { updateBackend: false })
    }

    const createSubTask = () => {
        setSubTasksView(true)

        requests.createSubtask(projectId, task.id, { title: '' }, res => {
            const newTask = {
                ...task,
                subAssignmentsResponse: [...task.subAssignmentsResponse, res],
            }

            update(newTask, { updateBackend: false })
        })
    }

    return (
        <div className="js--subtable">
            <div className="app-table__row d-flex align-items-center justify-content-between w-100 mb-1 js--subtable-row" onClick={() => navigate(`/project/${projectId}/${task.id}`)}>
                <div className="app-table__col w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        {task && task.subAssignmentsResponse[0] && (
                            <div className="p-4 svg-block cursor-pointer app-table__sub-open js--subtable-btn" onClick={e => {
                                e.stopPropagation()
                            }}>
                                <IconArrow onClick={() => setSubTasksView(!isSubtaskView)}/>
                            </div>
                        )}
                        <ListTaskStatus statusInfo={statusInfo}/>
                        <p className="font-medium ms-4">{task.title}</p>
                    </div>
                    <div className="app-table__hidden js--subtable-btn">
                        {/* {{> app-tooltip/tooltip-subtitle }} */}
                        <ListTaskSubtaskIcon create={createSubTask} changeView={() => setSubTasksView(!isSubtaskView)}/>
                    </div>
                </div>
                <div className="app-table__col w-100 d-flex justify-content-center">
                    {/* {{> app-dropdown/dropdown-mark3 }} */}
                    {task.tags.map(tag => (
                        <TagsCard key={tag.id} tag={tag} allTags={allTags} update={update} />
                    ))}

                    {!task.tags[0] && <TagsCard allTags={allTags} update={update} />}
                </div>
                <div className="app-table__col w-100 d-flex justify-content-center">
                    {/* {{> app-dropdown/dropdown-data2 }} */}
                    <DeadlineCard deadline={task.deadline} update={update} />
                </div>
                <div className="app-table__col w-100 p-2 d-flex justify-content-center">
                    {/* {{> app-dropdown/dropdown-avatar2 }} */}
                    <ExecutorTask
                        selectUsers={
                            (task.executors && users.filter(user => task.executors.includes(user.userId))) || []
                        }
                        users={
                            (task.executors && projectUsers.filter(user => !task.executors.includes(user.userId))) ||
                            projectUsers
                        }
                        taskId={task.id}
                    />
                </div>
                <div className="app-table__col w-100 d-flex justify-content-end pe-4">
                    <div className="w-100 d-flex justify-content-center">
                        {/* {{> app-dropdown/dropdown-priority3 }} */}
                        <PriorityCard priority={task.importanceScale} update={update} />
                    </div>
                    {/* {{> app-dropdown/dropdown-subtask }} */}
                </div>
            </div>
            <div className="app-table__subtitle js--subtable-content active">
                { isSubtaskView && task.subAssignmentsResponse.map(subtask => <ListSubtask subtask={subtask} updateTask={updateSubTask}/>) }
                
                {/* {{> app-table/row }}
                        {{> app-table/row2 }}
                        {{> app-table/row3 }} */}
            </div>
        </div>
    )
}

export default ListTask
