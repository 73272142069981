import { errorNotification } from '../pipeline/notificationPipeline'
import { sendRequest } from './fetch'
import { LOG, PARAMS, PATHS } from './lib'

import { usersRequests } from './users'
import { subtaskRequests } from './subtask'
import { commentsRequests } from './comments'
import { fileSystemRequests } from './fileSystem'
import { logInRequest } from './logInRequests'
import { paymentRequests } from './payment'

export const requests = {
    ...usersRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }),
    ...subtaskRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }),
    ...commentsRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }),
    ...fileSystemRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }),
    ...paymentRequests({ PATHS, PARAMS, LOG, sendRequest, errorNotification }),
    ...logInRequest,
    getUserTeam: (on = LOG, off = errorNotification) => {
        const path = PATHS.team + '/getUserTeams'
        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },

    getUserProjects: (on = LOG, off = errorNotification) => {
        const path = PATHS.projects
        
        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },

    createTeam: (data, on = LOG, off = errorNotification) => {
        const path = PATHS.team + '/createTeam'
        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify({
                title: data.title,
                emails: data.emails || []
            }),
        }

        sendRequest(path, params, on, off)
    },

    createProject: (teamId, projectData, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + teamId + '/createProject'

        console.log(teamId, projectData);
        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify(projectData),
        }

        sendRequest(path, params, on, off)
    },

    createTask: (projectId, title, statusId, on = LOG, off = errorNotification) => {
        const path = PATHS.tasks + '/' + projectId + '/createPriority'

        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify({
                title,
                statusId: statusId,
            }),
        }

        sendRequest(path, params, on, off)
    },

    getProject: (projectId, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId

        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },
    getTasksOnProject: (projectId, on = LOG, off = errorNotification) => {
        const path = PATHS.tasks + '/' + projectId + '/find'

        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify({}),
        }

        sendRequest(path, params, on, off)
    },
    changeTask: (taskData, on = LOG, off = errorNotification) => {
        const path = PATHS.tasks + '/' + taskData.id

        const params = {
            ...PARAMS,
            method: 'PUT',
            body: JSON.stringify(taskData),
        }

        sendRequest(path, params, on, off)
    },
    getCommentsForTask: (taskId, on = LOG, callbackOff = errorNotification) => {
        const path = `${PATHS.tasks}/${taskId}/comments/`
        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, callbackOff)
    },
    moveToStatus: (taskId, statusId, index, on = LOG, callbackOff = errorNotification) => {
        const path = PATHS.tasks + '/' + taskId + '/status/' + statusId + '/' + index + '/moveToStatus'

        const params = {
            ...PARAMS,
            method: 'POST',
        }

        sendRequest(path, params, on, callbackOff)
    },
    getTask: (taskId, on = LOG, off = errorNotification) => {
        const path = `${PATHS.tasks}/${taskId}/`

        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },
    getTeamById: (teamId, on = LOG, off = errorNotification) => {
        const path = `${PATHS.team}/${teamId}/getTeam`

        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },
    changeTeam: (teamData, on = LOG, off = errorNotification) => {
        const path = PATHS.team + '/' + teamData?.id + '/updateTeam'

        const params = {
            ...PARAMS,
            method: 'PUT',
            body: JSON.stringify(teamData),
        }

        sendRequest(path, params, on, off)
    },
    changeProject: (projectData, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectData?.projectId

        const params = {
            ...PARAMS,
            method: 'PUT',
            body: JSON.stringify(projectData),
        }

        sendRequest(path, params, on, off)
    },
    createColumn: (projectId, columnData, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/addStatus'

        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify(columnData),
        }

        sendRequest(path, params, on, off)
    },
    createTag: (projectId, taskId, tagData, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/task/' + taskId + '/tag'

        const params = {
            ...PARAMS,
            method: 'POST',
            body: JSON.stringify(tagData),
        }

        sendRequest(path, params, on, off)
    },
    removeTag: (projectId, taskId, tagId, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/task/' + taskId + '/tag/' + tagId

        const params = {
            ...PARAMS,
            method: 'DELETE',
        }

        sendRequest(path, params, on, off, true)
    },
    getTagsProject: (projectId, on = LOG, off = errorNotification) => {
        const path = `${PATHS.projects}/${projectId}/tags`

        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },
    getTagsTask: (projectId, taskId, on = LOG, off = errorNotification) => {
        const path = `${PATHS.projects}/${projectId}/task/${taskId}/tags`

        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },
    addTagToTAsk: (projectId, taskId, tagId, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/task/' + taskId + '/tag/' + tagId

        const params = {
            ...PARAMS,
            method: 'POST',
        }

        sendRequest(path, params, on, off)
    },
    getAllStatus: (projectId, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/getAllStatuses'

        const params = {
            ...PARAMS,
            method: 'GET',
        }

        sendRequest(path, params, on, off)
    },
    changeColumnData: (projectId, statusId, data, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/' + statusId + '/updateStatus'

        const params = {
            ...PARAMS,
            method: 'PUT',
            body: JSON.stringify(data)
        }

        sendRequest(path, params, on, off)
    },
    deleteProject: (projectId, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId

        const params = {
            ...PARAMS,
            method: 'DELETE',
        }

        sendRequest(path, params, on, off, true)
    },
    deleteTask: (taskId, on = LOG, off = errorNotification) => {
        const path = PATHS.tasks + '/' + taskId

        const params = {
            ...PARAMS,
            method: 'DELETE',
        }

        sendRequest(path, params, on, off, true)
    },
    deleteColumn: (projectId, statusId, on = LOG, off = errorNotification) => {
        const path = PATHS.projects + '/' + projectId + '/' + statusId + '/removeStatus'

        const params = {
            ...PARAMS,
            method: 'DELETE',
        }

        sendRequest(path, params, on, off, true)
    },
}
