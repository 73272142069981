import BlockMenuItem from '../Elements/BlockMenuItem'
import PopupCreateNewEntity from '../Elements/PopupCreateNewEntity'

import { useNavigate, useParams } from 'react-router-dom'
import { createProject } from '../../pipeline/CreatePipelines'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

function ProjectsMenuView({ projects }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { teamId } = useParams()
    const [isViewCreatePopup, setViewCreatePopup] = useState(false)

    const redirect = id => navigate(`/project/${id}`)

    const create = name =>
        createProject({
            dispatch,
            data: {
                name,
            },
            teamId,
            on: res => redirect(res.id),
        })

    return (
        <div className="mt-7 pt-7 w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
                <h2>Проекты этой команды</h2>
                <div className="d-flex align-items-center justify-content-end">
                    {/* {{> app-select/select-filter }} */}
                    <div className="cursor-pointer svg-block svg-w--24 p-1 ms-6">{/* {{> _images/grid }} */}</div>
                    <div className="cursor-pointer svg-block svg-w--24 p-1 ms-1">{/* {{> _images/list }} */}</div>
                </div>
            </div>
            <div className="project-row d-flex flex-wrap">
                <BlockMenuItem isCreate={true} click={() => setViewCreatePopup(true)}/>

                {projects &&
                    projects.map(({ id, title }) => (
                        <BlockMenuItem key={id} id={id} title={title} click={redirect} />
                    ))}
            </div>

            <PopupCreateNewEntity
                isView={isViewCreatePopup}
                title={'Создание нового проекта'}
                label={'Название проекта'}
                placeholder={'Название проекта'}
                onClose={() => setViewCreatePopup(false)}
                onCreate={create}
            />
        </div>
    )
}

export default ProjectsMenuView
