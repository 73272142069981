import { ReactComponent as IconPlus } from '../../assets/menu/plus5.svg'
import { hashCode, intToRGB } from '../../pipeline/helpers'

function LeftMenuItem({ isCreate, id, title, onRedirect, isActive, addClassStyle = '', addClassStyleBackground = '' }) {
    const color = typeof title === 'string' && intToRGB(hashCode(title))

    return (
        <div className="app-tooltip app-tooltip__right position-relative d-flex flex-column align-items-center w-100">
            <div className={'app-tooltip__btn app-tooltip__visible flex-center cursor-pointer ' + addClassStyle}>
                {isCreate && (
                    <div className={"app-menu__team flex-center font-large" + addClassStyleBackground} onClick={() => onRedirect()}>
                        {/* {{> _images/plus5}} */}
                        <IconPlus />
                    </div>
                )}
                {!isCreate && (
                    <div
                        className={'app-menu__team flex-center text-large' + (isActive ? '' : ' no-active') + addClassStyleBackground}
                        style={{ background: color }}
                        onClick={() => onRedirect(id)}
                    >
                        {title?.[0]}
                    </div>
                )}
            </div>
            {title && (
                <div className="app-tooltip__message">
                    <div className="p-1 ps-2 pe-2">
                        <p className="font-light">{title}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LeftMenuItem
