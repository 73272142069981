import { ReactComponent as IconArrow } from '../../assets/task/arrow2.svg'
import { useState } from 'react'

export function TaskDropduwnStatus({ statusInfo, statusId, update }) {
    const [isOpen, setOpen] = useState(false)

    const status = statusInfo.find(stat => stat.statusId === statusId)

    if (!status) {
        return (<></>)
    }

    return (
        <div className="app-dropdown app-dropdown__status-bg cursor-pointer position-relative svg-block js--dropdown">
            <div
                className="app-dropdown__open js--dropdown-open pt-10 pb-10 ps-4 pe-4 border-radius-4 d-flex align-items-center"
                style={{ background: status.color }}
                onClick={() => setOpen(!isOpen)}
            >
                <p className="text-medium color-white me-2">{status.name}</p>
                <IconArrow />
            </div>

            <div
                className={`app-dropdown__overlay js--dropdown-content js--dropdown-content-close ${
                    isOpen ? 'open' : ''
                }`}
            >
                {statusInfo.map(item => (
                    <div
                        className="pt-10 pb-10 ps-4 pe-4 d-flex align-items-center"
                        key={item.statusId}
                        onClick={() => {
                            setOpen(false)
                            update({ statusId: item.statusId })}}
                    >
                        <div className="circle-20" style={{ background: item.color }}></div>
                        <p className="ms-1">{item.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TaskDropduwnStatus
