export const configColorsColumn = {
    0: '#F3DA14',
    1: '#F85CB2',
    2: '#E97474',
    3: '#3C7ABC',
    4: '#02B07E',
    5: '#5C5950',
    6: '#FEF7DD',
    7: '#A635FC',
    8: '#F7B240',
    9: '#D69D74',
    10: '#C633A2',
    11: '#74B238',
}
