import store from '../store'
import { setTasks, setTags, deleteTask } from '../store/TaskProperties'
import { intToRGB, hashCode } from './helpers'
import { setClientInfo } from '../store/Users'
import { changeViewNotification } from '../store/Notification'
import { changeTeams, deleteProjectStore, updateProject } from '../store/Teams'

import { requests } from '../requests'

export function setBoardData(dispatch, projectId) {
    requests.getTasksOnProject(projectId, data => {
        dispatch(setTasks(data.tasks))
    })

    requests.getTagsProject(projectId, data => {
        dispatch(setTags(data.tags))
    })

    // requests.getAllStatus(projectId, data => {
    //     setStatuses(data.statuses)
    // })
}

export function deleteTaskPipeline(taskId, on) {
    const dispatch = store.dispatch

    requests.deleteTask(taskId, () => {
        dispatch(deleteTask({ taskId }))
        on && on(taskId)
    })
}

export function changeClientInfo(clientInfo) {
    const dispatch = store.dispatch

    requests.changeClientInfo(clientInfo, res => {
        dispatch(setClientInfo({ name: res.name, userId: res.userId }))
    })
}

export function changePassword(password) {
    const notificationSuccess = {
        isView: true,
        type: 'success',
        text: 'Новый пароль установлен',
    }

    const dispatch = store.dispatch

    requests.changePassword(password, () => {
        dispatch(changeViewNotification(notificationSuccess))
    })
}

export function deleteColumn(projectId, columnId) {
    const dispatch = store.dispatch

    requests.deleteColumn(projectId, columnId, () => {
        const project = store.getState().teams.projects.find(proj => proj.id === projectId)
        dispatch(updateProject({
            ...project,
            statusesResponse: {
                ...project.statusesResponse,
                statuses: project.statusesResponse.statuses.filter(status => status.statusId !== columnId)
            }
        }))
    })
}

export function deleteUserOnTeam(teamId, userId) {
    const dispatch = store.dispatch
    const state = store.getState()

    const changeTeam = state.teams.teams.find(te => te.id === teamId)

    dispatch(
        changeTeams({
            type: 'team',
            data: {
                ...changeTeam,
                membersInfo: changeTeam?.membersInfo.filter(user => user.userId !== userId),
            },
        })
    )
}

export function addUserOnProject(user, projectId) {
    const dispatch = store.dispatch
    const state = store.getState()

    const changeProject = state.teams.projects.find(pr => pr.id === projectId)

    requests.addUserToProject(projectId, user.userId, () => {
        dispatch(
            changeTeams({
                type: 'project',
                data: {
                    ...changeProject,
                    userInfos: [...changeProject.userInfos, user],
                },
            })
        )
    })
}

export function updateStatusProject(projectId, statusData) {
    const onChangeTitle = updateServerDataStatus => {
        const dispatch = store.dispatch
        const state = store.getState()
        const project = state.teams.projects.find(pr => pr.id === projectId)

        dispatch(
            updateProject({
                id: projectId,
                statusesResponse: {
                    ...project.statusesResponse,
                    statuses: project.statusesResponse.statuses.map(status => {
                        if (status.statusId === updateServerDataStatus.statusId) {
                            return updateServerDataStatus
                        }

                        return status
                    }),
                },
            })
        )
    }

    requests.changeColumnData(projectId, statusData.statusId, { title: statusData.title }, onChangeTitle)
}

export function deleteUserOnProject({ userId }, projectId) {
    const dispatch = store.dispatch
    const state = store.getState()

    const changeProject = state.teams.projects.find(pr => pr.id === projectId)

    requests.deletUsersOnProject(projectId, userId, () => {
        dispatch(
            changeTeams({
                type: 'project',
                data: {
                    ...changeProject,
                    userInfos: changeProject?.userInfos.filter(user => user.userId !== userId),
                },
            })
        )
    })
}

export function deleteProject(projectId, on) {
    const dispatch = store.dispatch

    requests.deleteProject(projectId, () => {
        dispatch(
            deleteProjectStore({
                projectId,
            })
        )

        on && on()
    })
}

export function resetPassword(email) {
    const notificationSuccess = {
        isView: true,
        type: 'success',
        text: 'Новый пароль выслан вам на почту',
    }

    const dispatch = store.dispatch

    console.log(requests, requests.resetPassword)
    requests.resetPassword(email, () => dispatch(changeViewNotification(notificationSuccess)))
}

export function uploadFileTask(taskId, file) {
    requests.addFileToTask(taskId, file, fileData => {
        const state = store.getState()
        const dispatch = store.dispatch
        const { tasks } = state.tasks
        const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

        setTasksBoard(
            tasks.map(item => {
                if (item.id === taskId) {
                    return {
                        ...item,
                        filesData: [...item.filesData, fileData],
                    }
                }
                return item
            })
        )
    })
}

export function uploadExecutorTask(taskId, executorId) {
    requests.addExecutorTask(taskId, executorId, taskData => {
        const state = store.getState()
        const dispatch = store.dispatch
        const { tasks } = state.tasks
        const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

        setTasksBoard(
            tasks.map(item => {
                if (item.id === taskId) {
                    return {
                        ...item,
                        executors: taskData.executors,
                    }
                }
                return item
            })
        )
    })
}

const defaultSettings = {
    updateBackend: true,
}

export const updateCard = ({ taskData, projectId }, settings = defaultSettings, on = () => {}) => {
    const { updateBackend } = settings
    const state = store.getState()
    const dispatch = store.dispatch

    const { tasks, tags } = state.tasks

    const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

    if (taskData.newTag || taskData.addTag) {
        tagToTask({
            projectId,
            taskData,
            tasks,
            tags,
            setTasks: setTasksBoard,
            setTags: newTags => dispatch(setTags(newTags)),
        })

        return
    }

    setTasksBoard(
        tasks.map(item => {
            if (item.id === taskData.id) {
                return {
                    ...item,
                    ...taskData,
                }
            }
            return item
        })
    )

    updateBackend &&
        requests.changeTask(taskData, data => {
            setTasksBoard(
                tasks.map(item => {
                    if (item.id === data.id) {
                        return data
                    }
                    return item
                })
            )

            on(data)
        })
}

const tagToTask = ({ projectId, taskData, tasks, tags, setTasks, setTags }) => {
    if (!taskData) return

    if (taskData.newTag) {
        requests.createTag(
            projectId,
            taskData.id,
            {
                color: intToRGB(hashCode(taskData.newTag.name)),
                name: taskData.newTag.name,
            },
            data => {
                const newTags = [...tags, data]

                setTags(newTags)

                setTasks(
                    tasks.map(item => {
                        if (item.id === taskData.id) {
                            return {
                                ...item,
                                tags: [...item.tags, data],
                            }
                        }
                        return item
                    })
                )
            }
        )
        return
    }

    if (taskData.addTag) {
        const isTagOnTask = tasks.find(task => task.id === taskData.id).tags.find(tag => tag.id === taskData.addTag.id)

        if (!isTagOnTask) {
            requests.addTagToTAsk(projectId, taskData.id, taskData.addTag.id, () => {
                setTasks(
                    tasks.map(item => {
                        if (item.id === taskData.id) {
                            return {
                                ...item,
                                tags: [...item.tags, taskData.addTag],
                            }
                        }
                        return item
                    })
                )
            })
            return
        } else {
            requests.removeTag(projectId, taskData.id, taskData.addTag.id, () => {
                setTasks(
                    tasks.map(item => {
                        if (item.id === taskData.id) {
                            return {
                                ...item,
                                tags: item.tags.filter(tag => tag.id !== taskData.addTag.id),
                            }
                        }
                        return item
                    })
                )
            })

            return
        }
    }
}
