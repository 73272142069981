import { useNavigate } from 'react-router-dom'
import IconAvatar from '../Elements/IconAvatar'
import { useState } from 'react'
//import { changeViewSettingProfile } from '../../store/Users'

import { ReactComponent as IconLogout } from '../../assets/menu/logout.svg'
import { ReactComponent as IconSettings } from '../../assets/menu/settings.svg'

import { deleteSaveToken } from '../../requests/fetch'

function DropdownAvatar({ userId }) {
    const [isOpenSetting, setOpenSetting] = useState('') // '' || 'open'
    const navigate = useNavigate()

    return (
        <div className="app-dropdown cursor-pointer position-relative svg-block js--dropdown" >
            <IconAvatar
                userId={userId}
                addClass="app-content__avatar cursor-pointer flex-center mt-1 mb-1 ms-1 me-1 js--dropdown-open"
                onClick={() => setOpenSetting(isOpenSetting === ''? 'open' : '')}
            />
            <div className={"app-dropdown__overlay app-dropdown__overlay-avatar js--dropdown-content " + isOpenSetting}>
                <p
                    className="pt-4 pb-4 color-primary text-little d-flex align-items-center"
                    data-show-modal="overlay-profile"
                    onClick={() => {
                        setOpenSetting('')
                        navigate('userProfile')
                        //dispatch(changeViewSettingProfile(true))
                    }}
                >
                    <IconSettings className="me-1"/>
                    Настройки
                </p>
                <p className="pt-4 pb-4 color-primary text-little d-flex align-items-center" onClick={() => {
                        navigate('/login')
                        deleteSaveToken()
                    }}>
                    <IconLogout className="me-1"/>
                    Выйти
                </p>
            </div>
        </div>
    )
}

export default DropdownAvatar
