import { ReactComponent as IconSubtask } from '../../assets/kanban/subtask.svg'
import { ReactComponent as IconPlus } from '../../assets/kanban/plus2.svg'

function ListTaskSubtaskIcon({ changeView, create }) {
    const openSubtasks = e => {
        e.stopPropagation()
        changeView()
    }
    return (
        <div className="app-tooltip app-tooltip__subtitle position-relative d-flex flex-column align-items-center" onClick={openSubtasks}>
            <div className="app-tooltip__btn flex-center cursor-pointer svg-block w-100 h-100">
                <div className="app-tooltip__subtitle-icon">
                    {/* {{> _images/subtask }} */}
                    <IconSubtask />
                </div>

                <p className="ms-1 app-tooltip__subtitle-text">
                    3
                </p>
                <div className="app-card__subtask-line ms-1 me-1 d-block"></div>
                {/* {{> _images/plus2 }} */}
                <IconPlus
                    onClick={e => {
                        e.stopPropagation()
                        create()
                    }}
                />
            </div>
            <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                <p>Подзадачи</p>
            </div>
        </div>
    )
}

export default ListTaskSubtaskIcon
