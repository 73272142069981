import { ReactComponent as IconPlus } from '../../assets/kanban/plus2.svg'
import { ReactComponent as IconClose } from '../../assets/kanban/close2.svg'
import { useState, useEffect } from 'react'

//import IconUser from './IconUser'

function KanbanAddTaskOnEndColumn({ isHover, createTask }) {
    const [isCreate, setShowCreate] = useState(false)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        if (!isHover) setShowCreate(false)

        return () => {}
    }, [isHover])

    return (
        <>
            {isHover && (
                <div className="app-card__newcard js--newtask">
                    {/* {{> app-card/new_task/task-add }}
                    {{> app-card/new_task/card-newtask }} */}

                    {!isCreate && (
                        <p
                            className="pt-1 pb-1 ps-2 pe-2 d-flex align-items-center mt-4 color-task cursor-pointer app-card__newtask-add js--newtask-btn"
                            onClick={() => setShowCreate(!isCreate)}
                        >
                            <span className="svg-block svg-w--24 me-2">
                                <IconPlus />
                            </span>
                            Новая задача
                        </p>
                    )}

                    {isCreate && (
                        <div className="app-card app-card__new js--newtask-content mt-4">
                            <div className="ps-4 pe-4 pt-4 position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center me-2">
                                        <div
                                            className="svg-block svg-w--8 me-4 cursor-pointer js--newtask-close"
                                            onClick={() => setShowCreate(false)}
                                        >
                                            {/* {{> _images/close2 }} */}
                                            <IconClose />
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Введите название задачи"
                                            className="w-100"
                                            value={inputValue}
                                            onChange={e => setInputValue(e.target.value)}
                                        />
                                    </div>
                                    {/* <IconUser /> */}
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-4 pt-2 pb-2">
                                    {/* {{> app-card/elements/params }} */}
                                    <button
                                        className="btn btn-grey pt-1 pb-1 ps-2 pe-2 text-primary"
                                        onClick={() => {
                                            setInputValue('')
                                            createTask(inputValue)
                                        }}
                                    >
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default KanbanAddTaskOnEndColumn
