import { createSlice } from '@reduxjs/toolkit'
import { createTimerNotification } from '../pipeline/CreatePipelines'

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState: {
        isView: false,
        type: 'success',
        text: '',
        timerId: null,
    },
    reducers: {
        changeViewNotification: (state, actions) => {
            const { isView, type, text } = actions.payload

            if (isView) {
                const timerId = createTimerNotification(state.timerId)
                state.timerId = timerId
            }
            

            state.isView = isView
            state.type = type
            state.text = text
        },
    },
})

export const { changeViewNotification } = NotificationSlice.actions

export default NotificationSlice.reducer


