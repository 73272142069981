import { useSelector } from 'react-redux'
import { useState } from 'react'
import { requests } from '../../requests'
import { useParams } from 'react-router-dom'
import { addNewUserToTeamNotification } from '../../pipeline/notificationPipeline'
import { useNavigate } from 'react-router-dom'

import PopupCreateNewEntity from '../Elements/PopupCreateNewEntity'
import DropdownAvatar from '../Components/DropdownAvatar'

function Header() {
    const { teamId } = useParams()
    const navigate = useNavigate()

    const team = useSelector(state => state.teams.teams.find(te => te.id === Number(teamId)))
    const userInfo = useSelector(state => state.users.clientInfo)

    const [isViewCreatePopup, setViewCreatePopup] = useState(false)

    return (
        <div className="d-flex justify-content-between ms-4">
            <div className="d-flex align-items-center">
                {/* <IconLogo
                    className='cursor-pointer'
                    onClick={() => {
                        if (!teamId) return

                        navigate(`/team/${teamId}/`)
                    }}
                /> */}
                {/* {teamName && (
                    <>
                        <p className="color-grizzle">{teamName}</p>
                        <span className="color-grizzle ms-1 me-1">/</span>
                        <p>{projectName}</p>
                    </>
                )} */}
            </div>
            <div className="d-flex align-items-center">
                <button
                    className="btn btn-blue d-flex align-items-center svg-block me-4"
                    onClick={() => setViewCreatePopup(true)}
                >
                    Пригласить в команду
                </button>
                <button className="btn btn-primary d-flex align-items-center" onClick={() => navigate(`/team/${teamId}/tariff`)}>
                    {/* <span className="me-1">{{> _images/users }}</span> */}
                    Улучшить
                </button>
                <div className="me-4">{/* {{> app-input/input-search }} */}</div>
                {/* {{> app-dropdown/dropdown-avatar }} */}
                {/* <IconAvatar /> */}
                <DropdownAvatar userId={userInfo?.userId} />
            </div>

            <PopupCreateNewEntity
                isView={isViewCreatePopup}
                title={`Пригласить в ${team?.title}`}
                label='Email'
                placeholder='name@example.com'
                buttonTitle='Пригласить'
                onClose={() => setViewCreatePopup(false)}
                onCreate={text => {
                    setViewCreatePopup(false)
                    requests.addNewUserToTeam(teamId, text, addNewUserToTeamNotification)
                }}
            />
        </div>
    )
}

export default Header
