import { useEffect, useState } from 'react'

export function useCheckOutsideClick(ref, on = () => {}) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                on(event.target)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, on])
}

export function useStateVisibleComponent(ref, on = () => {}) {
    const [isVisibility, setVisibility] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            if (isVisibility && ref.current && !ref.current.contains(event.target)) {
                setVisibility(false)
                on(event.target)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, on, isVisibility])

    return [isVisibility, setVisibility]
}
