import { ReactComponent as IconArrow } from '../../assets/task/arrow3.svg'
import { useStateVisibleComponent } from '../CustomHooks/ClickOutside'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

function DropdownTeamInfo({ id, title, users }) {
    const ref = useRef()
    const navigate = useNavigate()
    const [isVisibility, setVisibility] = useStateVisibleComponent(ref)

    const addClassDropdown =
        'app-dropdown__overlay app-dropdown__overlay-avatar js--dropdown-content js--dropdown-content-close' +
        (isVisibility ? ' open' : '')

    const addClassButtonSelection =
        'app-dropdown app-dropdown__team cursor-pointer position-relative svg-block js--dropdown' +
        (isVisibility ? ' active' : '')

    const addClassArrowRevert =
        'app-dropdown__open cursor-pointer p-4 js--dropdown-open' + (isVisibility ? ' active' : '')

    return (
        <div className={addClassButtonSelection} ref={ref}>
            <div
                className={addClassArrowRevert}
                onClick={() => {
                    setVisibility(!isVisibility)
                }}
            >
                <div className="d-flex justify-content-between w-100">
                    <div>
                        <p className="text-large">{ title }</p>
                        <p className="text-little font-light mt-1">{ users.length } участников</p>
                    </div>
                    <IconArrow />
                </div>
            </div>

            <div className={addClassDropdown}>
                <div className="p-4 w-100">
                    <p className="font-light p-0 mt-1" onClick={() => navigate('profile')}>Настройка команды</p>
                    <p className="font-light p-0 mt-5" onClick={() => navigate('users')}>Участники</p>
                    <div className="app-border9 mt-5"></div>
                    <p className="font-light p-0 mt-5">Покинуть команду</p>
                </div>
            </div>
        </div>
    )
}

export default DropdownTeamInfo
