import { TextAndInput } from './MiniComponents'

function DescriptionTask({ title, description, update }) {
    return (
        <div>
            <TextAndInput
                text={title}
                preholder={'Название задачи'}
                addClass={'text-big w-100 shadow-input'}
                update={date => update({ title: date })}
                onSaveTimer
            />

            <div className="mt-6 p-9 border-radius-8 bg-light">
                <TextAndInput
                    text={description}
                    preholder={'Описание задачи'}
                    onCheckEnter={false}
                    addClass={'text-medium w-100 bg-light space-pre-wrap'}
                    update={date => update({ description: date })}
                    onSaveTimer
                    isCheckEnter={false}
                    isEditingSelection={false}
                />
            </div>
        </div>
    )
}

export default DescriptionTask
