import { TaskNameProject } from "../Elements/MiniComponents"
import { ReactComponent as IconClose } from '../../assets/kanban/close2.svg'
import { useGetProjectAndTeamName } from "../CustomHooks/GetProjectAndTeamName";
import { useParams } from "react-router";

function TaskFooter({ onClose, taskId }) {
    const { projectId } = useParams()
    const { projectName, teamName } = useGetProjectAndTeamName(Number(projectId))

    return (
        <div className="overlay-header d-flex align-items-center justify-content-between pt-4 pb-4 ps-6 pe-6">
            <TaskNameProject teamName={teamName} projectName={projectName}/>
            <div className="font-bold color-grey5 text-small">
                id: { taskId }
            </div>
            <div className="d-flex align-items-center">
                {/* {{> app-dropdown/dropdown-subtask }} */}
                <div className="svg-w--16 svg-block p-10 ms-2 cursor-pointer js--overlay-close" onClick={onClose}>
                    <IconClose />
                </div>
            </div>
        </div>
    )
}

export default TaskFooter