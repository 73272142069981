import { ReactComponent as IconSubTask } from '../../assets/kanban/subtask.svg'
import { ReactComponent as IconPlus } from '../../assets/kanban/plus2.svg'



function IconSubTaskCard({ subTasks, changeView, createSubTask }) {
    return (
        <div className="app-tooltip position-relative w-100 h-100 d-flex flex-column align-items-center" onClick={e => {
            e.stopPropagation()
            changeView()
        }}>
            <div className="app-tooltip__btn flex-center cursor-pointer svg-block w-100 h-100">
                <div className="app-card__subtask-icon">
                    {/* {{> _images/subtask }} */}
                    <IconSubTask />
                </div>

                {subTasks && subTasks.length > 0 && <p className="ms-1 app-card__subtask-text">{subTasks.length}</p>}
                <div className="app-card__subtask-line ms-1 me-1 d-block"></div>
                {/* {{> _images/plus2 }} */}
                <IconPlus onClick={e => {
                    e.stopPropagation()
                    createSubTask()
                }}/>
            </div>
            <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                <p>Подзадачи</p>
            </div>
        </div>
    )
}

export default IconSubTaskCard