import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { ReactComponent as IconLogo } from '../../assets/logo-pro.svg'
import { useGetProjectAndTeamName } from '../CustomHooks/GetProjectAndTeamName'
import { requests } from '../../requests'

import ProjectAvatarList from '../Components/ProjectAvatarList'
import SettingProjectPopup from '../Components/SettingProjectPopup'

function ProjectHeader() {
    const navigate = useNavigate()
    const { projectId } = useParams()

    const [imgUrl, setImgUrl] = useState('')
    const [isViewSettingPopup, setViewSettingPopup] = useState(false)

    const { projectName, teamName, teamId } = useGetProjectAndTeamName(Number(projectId))

    useEffect(() => {
        requests.getProjectFilePreview(projectId, setImgUrl, () => {
            requests.getRandomImage(setImgUrl)
        })

        return () => {}
    }, [projectId])

    const onClickSetting = () => setViewSettingPopup(!isViewSettingPopup)

    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <IconLogo
                    className="cursor-pointer"
                    onClick={() => {
                        if (!teamId) return

                        navigate(`/team/${teamId}/`)
                    }}
                />
                {teamName && (
                    <>
                        <p
                            className="color-grizzle cursor-pointer"
                            onClick={() => {
                                if (!teamId) return

                                navigate(`/team/${teamId}/`)
                            }}
                        >
                            {teamName}
                        </p>
                        <span className="color-grizzle ms-1 me-1">/</span>
                        <p>{projectName}</p>
                    </>
                )}
            </div>
            <div className="d-flex align-items-center">
                <ProjectAvatarList projectId={projectId} />
                <button
                    className="btn btn-primary d-flex align-items-center ms-6"
                    onClick={() => navigate(`/team/${teamId}/tariff`)}
                >
                    Улучшить
                </button>
                {/* <a href="/index3.html?cabinet-support" className="flex-center svg-block svg-w--24 p-2 ms-6">
                    {{> _images/question2 }}
                </a> */}
                {/* {{> app-notification/notification }} */}
                {/* <div className="ms-6">
                    <div className="app-content__avatar flex-center cursor-pointer" ></div>
                </div> */}
                <div className="ms-6">
                    <img
                        className="app-content__avatar flex-center cursor-pointer"
                        src={imgUrl}
                        onClick={onClickSetting}
                        alt="user avatar"
                    />

                    <SettingProjectPopup
                        isView={isViewSettingPopup}
                        changeView={setViewSettingPopup}
                        projectId={projectId}
                        imgUrl={imgUrl}
                        changeImage={file => {
                            requests.changeLogoProject(projectId, file, () =>
                                requests.getProjectFilePreview(projectId, setImgUrl)
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProjectHeader
