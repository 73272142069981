import { useState } from 'react'
import { changeViewNotification } from '../../store/Notification'
import { useDispatch } from 'react-redux'
//import { ReactComponent as IconLogo } from '../../assets/logo.svg'

function Registration({ switcToLogIn, on, type = 'registration' }) {
    const [registerData, setRegisterData] = useState({
        email: '',
        username: '',
        password: '',
    })
    const dispatch = useDispatch()

    const notificationSuccess = {
        isView: true,
        type: 'success',
        text: 'Спасибо за регистрацию, Вам на почту отправлено письмо с логином и паролем для входа в аккаунт',
    }

    return (
        <>
            <header>
                <div className="login-container mt-8">
                    <header>
                        <div className="login-container">
                            <div className="header__wrapper">
                                <div className="header__item">
                                    {/* <div className="logo mt-4">
                                        <IconLogo />
                                    </div> */}
                                </div>
                                <div className="header__item registration">
                                    <button className="btn-submit cursor-pointer" onClick={() => switcToLogIn()}>
                                        Войти
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </header>
            <section className="form">
                <form action="#">
                    <h1>Регистрация</h1>
                    <div className="input">
                        <input
                            type="text"
                            placeholder="Имя"
                            value={registerData.username}
                            onChange={e =>
                                setRegisterData({
                                    ...registerData,
                                    username: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="input">
                        <input
                            type="email"
                            placeholder="Email"
                            value={registerData.email}
                            onChange={e =>
                                setRegisterData({
                                    ...registerData,
                                    email: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="input">
                        <input
                            type="password"
                            placeholder="Пароль"
                            value={registerData.password}
                            onChange={e =>
                                setRegisterData({
                                    ...registerData,
                                    password: e.target.value,
                                })
                            }
                        />
                        <img src="../img/registration/eye-off.svg" alt="" />
                        <span>Поле обязательное</span>
                        <span>Поле должно содержать минимум 8 символов</span>
                        <span>Поле должно содержать и латинские буквы и цифры</span>
                    </div>
                    <div className="submit">
                        <button
                            className="btn-submit cursor-pointer"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                on(registerData, message => {
                                    console.log(message, 'notificationSuccess')
                                    switcToLogIn()
                                    dispatch(changeViewNotification(notificationSuccess))
                                })
                            }}
                        >
                            Зарегистрироваться
                        </button>
                        {/* <p>Нажимая на кнопку Зарегистрироваться, я соглашаюсь с <a href="#">Политикой конфиденциальности</a></p> */}
                    </div>
                </form>
            </section>
        </>
    )
}

export default Registration
