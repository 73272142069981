import IconAvatar from './IconAvatar'

function ProjectUserItem({ userId, name, onClick = () => {}, typeButton }) {
    return (
        <div className="app-command__table-row pt-2 pb-2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <IconAvatar userId={userId} addClass="circle-36" />
                <p className="ms-2 text-little">{name}</p>
            </div>
            {typeButton === 'delete' && (
                <button
                    className="btn btn-error p-1 ps-2 pe-2 text-small h-max"
                    onClick={e => {
                        e.stopPropagation()
                        onClick(e)
                    }}
                >
                    Удалить
                </button>
            )}
            {typeButton === 'create' && (
                <button
                    className="btn btn-blue2 p-1 ps-2 pe-2 text-small h-max"
                    onClick={e => {
                        e.stopPropagation()
                        onClick(e)
                    }}
                >
                    Назначить
                </button>
            )}
        </div>
    )
}

export default ProjectUserItem
