import { useEffect } from "react"

export function useCheckKeyPressed(keyCode: number, on: () => void, isVisible = true, dependencies = []) {
    useEffect(() => {
      
        const listenerKeyboard = (e: KeyboardEvent) => {
			
			if (e.keyCode === keyCode && isVisible) {
				e.preventDefault();
				e.stopPropagation();

				on && on()
			}
		}

		document.addEventListener('keydown', listenerKeyboard)
		return () => {
			document.removeEventListener('keydown', listenerKeyboard)
		}
		// eslint-disable-next-line 
    }, [ keyCode, on, isVisible ].concat(dependencies))
}