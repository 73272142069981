import TeamDropdownSelect from "./TeamDropdownSelect"
import { useNavigate } from "react-router-dom"


function TeamSettingMenu() {
    const navigate = useNavigate()

    return (
        <div className="page-cabinet__sidebar me-9">
            <div className="p-9">
                <TeamDropdownSelect />
            </div>
            <div className="app-border10 w-100">&nbsp;</div>
            <div className="ps-9 pe-9">
                <div className="pt-6 pb-6">
                    <p
                        className="page-cabinet__sidebar-item d-flex align-items-center cursor-pointer js--cabinet-btn"//active
                        onClick={() => navigate(PATH_CONFIG.settingProfile)}
                    >
                        {/* <span className="svg-w--24 svg-block me-2">{{> _images/user2}}</span> */}
                        Настройка профиля
                    </p>
                </div>

                <p className="font-medium mt-6">Команды</p>
                <p
                    className="page-cabinet__sidebar-item mt-4 d-flex align-items-center cursor-pointer js--cabinet-btn"
                    onClick={() => navigate(PATH_CONFIG.settingTeam)}
                >
                    {/* <span className="svg-w--24 svg-block me-4">{{> _images/profile}}</span> */}
                    Профиль команды
                </p>
                <p
                    className="page-cabinet__sidebar-item mt-4 d-flex align-items-center cursor-pointer js--cabinet-btn"
                    onClick={() => navigate(PATH_CONFIG.users)}
                >
                    {/* <span className="svg-w--24 svg-block me-4">{{> _images/user3}}</span> */}
                    Пользователи
                </p>
                <p
                    className="page-cabinet__sidebar-item mt-4 d-flex align-items-center cursor-pointer js--cabinet-btn"
                    onClick={() => navigate(PATH_CONFIG.tariff)}
                >
                    {/* <span className="svg-w--24 svg-block me-4">{{> _images/tariff}}</span> */}
                    Тарифы
                </p>
                <p
                    className="page-cabinet__sidebar-item mt-4 d-flex align-items-center cursor-pointer js--cabinet-btn"
                    onClick={() => navigate(PATH_CONFIG.support)}
                >
                    {/* <span className="svg-w--24 svg-block me-4">{{> _images/support}}</span> */}
                    Центр поддержки
                </p>
            </div>
        </div>
    )
}


export default TeamSettingMenu

const PATH_CONFIG = {
    settingProfile: '../userProfile',
    settingTeam: '../profile',
    users: '../users',
    tariff: '../tariff',
    support: '../support',
}