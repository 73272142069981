import { useCheckOutsideClick } from '../CustomHooks/ClickOutside'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setViewModal } from '../../store/Teams'
import { configColorsColumn } from '../../store/configs'
import { useParams } from 'react-router-dom'
import { requests } from '../../requests'
import { addColumn } from '../../store/Teams'

function ModalCreateColumn() {
    const viewModal = useSelector(state => state.teams.isViewModal)
    const dispatch = useDispatch()
    const ref = useRef()
    const { projectId } = useParams()

    const [text, setText] = useState('')
    const [color, setColor] = useState('')

    useCheckOutsideClick(ref, () => dispatch(setViewModal(false)))

    const createColumn = () => {
        const columnDate = {
            color: color,
            title: text,
        }
        
        requests.createColumn(projectId, columnDate, res => {
            dispatch(addColumn({
                projectId: Number(projectId),
                column: res
            }))
        })

        dispatch(setViewModal(false))
    }

    const addClass = viewModal === 'createColumn' ? ' overlay-show' : ''

    return (
        <div
            className={'overlay overlay-status d-flex align-items-center justify-content-center w-100 h-100' + addClass}
        >
            <div className="overlay-wrap position-relative p-9 w-100 open" ref={ref}>
                <p className="font-bold text-large text-center">Создать новый статус</p>
                <p className="font-bold mt-9">Название</p>
                <div className="overlay-status__input mt-4">
                    <input
                        type="text"
                        placeholder="Название статуса"
                        className="w-100"
                        value={text}
                        onChange={e => setText(e.target.value)}
                    />
                </div>
                <p className="font-bold mt-9">Цвет</p>
                <div className="d-flex flex-wrap">
                    {Object.keys(configColorsColumn).map(colorId => (
                        <div
                            key={colorId}
                            className="circle-20 mt-4 me-4 cursor-pointer"
                            style={{ background: configColorsColumn[colorId] }}
                            onClick={() => setColor(configColorsColumn[colorId])}
                        />
                    ))}
                </div>
                <div className="d-flex align-items-center mt-9 w-100">
                    <button
                        className="btn btn-primary me-10 pt-2 pb-2 btn-padding-revert btn-w-100 js--overlay-close"
                        onClick={createColumn}
                    >
                        Сохранить
                    </button>
                    <button
                        className="btn btn-blue ms-10 pt-2 pb-2 btn-padding-revert btn-w-100 js--overlay-close"
                        onClick={() => dispatch(setViewModal(false))}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalCreateColumn
