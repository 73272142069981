

function ListTaskStatus({ statusInfo }) {
    return (
        <div className="app-tooltip app-tooltip__status app-tooltip__status--open position-relative d-flex flex-column align-items-center">
            <div className=" p-10">
                <div className="flex-center cursor-pointer svg-block"
                    style={{
                        background: statusInfo?.color
                    }}
                ></div>
            </div>

            <div className="app-tooltip__message pt-10 pb-10 ps-4 pe-4">
                <p>{ statusInfo?.name }</p>
            </div>
        </div>
    )
}

export default ListTaskStatus