import { ReactComponent as IconUpload } from '../../assets/task/upload.svg'
import { useRef } from 'react'
import { readUploadFile } from '../../pipeline/helpers'

function UploadFileTask({ taskId, onUploadFile }) {
    const inputFile = useRef(null)

    const choiseUploadFiles = () => {
        const input = inputFile.current
        const files = input.files

        readUploadFile(taskId, files, onUploadFile)
    }

    return (
        <div className="w-50 d-flex justify-content-center pt-9 pb-9 ps-6 pe-6 cursor-pointer" onClick={e => {
                e.stopPropagation()

                inputFile.current.click()
            }}>
            <div className="app-upload cursor-pointer">
                <div className=" d-flex align-items-center position-relative">
                    <input
                        ref={inputFile}
                        type="file"
                        id="fileselect"
                        name="fileselect[]"
                        multiple={true}
                        onChange={choiseUploadFiles}
                        
                        style={{
                            display: 'none'
                        }}
                    />
                    <div className="d-flex align-items-center svg-block" id="filedrag">
                        <IconUpload />
                        <p className="text-little color-hoar ms-2">Перетащите файлы сюда, чтобы прикрепить</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadFileTask

